import React from "react";
import './style.scss';
import Modal from "react-bootstrap/Modal";
import rejectedImage from "../../assets/images/rejected@2x.png";
import crossImage from "../../assets/images/close (4).png";
import Textarea from "../CustomeTextarea";
import CustomButton from "../Button/Button";

const RejectedTicketModal = ({ hide, show, title ,title1 , textOnChange ,textvalue , handleRejectTicket}) => {

    return (
        <Modal show={show} centered className="sucess-content sucess_content_modal">
            <Modal.Body className="sucess-modal">
                <div className="modal-container">
                    <img
                        src={crossImage}
                        alt=""
                        className="cross-img"
                        onClick={() => hide()}
                    />
                    <div className="row moodal">
                        <img src={rejectedImage} alt="" className="modal-img" />
                        <h4 className="success_title1" style={{ textAlign: "center" }}>
                        {title} <span className="span_color">{title1}</span>
                        </h4>
                        <div className="col-md-12 mt-3">
                            <Textarea
                                style={'textarea_bg'}
                                placeholder={'Please write a comment....'}
                                textvalue={textvalue}
                                onChange={textOnChange}
                            />
                        </div>
                        <div className="col-md-12 text-center">
                            <CustomButton
                                children={"Submit"}
                                style={'btn--solid'}
                                onClick={handleRejectTicket}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default RejectedTicketModal;
