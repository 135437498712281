import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./style.scss";
import crossImage from "../../assets/images/close (4).png";
import notify from "../../shared/notify";
import { useStores } from "../../stores";
import CustomButton from "../Button/Button";
import Loader from "../helper/Loader/loader";
import CustomInput from "../CustomInput";

const ChangePassword = (props) => {
    const [password, setPassword] = useState("")
    const { mainStore } = useStores()
    const [isLoading, setIsLoading] = useState(false)


    const Approval = async () => {
        setIsLoading(true)
        let payload = {
            password: password
        }
        await mainStore.changePassword(payload,(response) => {
                if (response?.data?.meta?.status === "success") {
                    notify(response?.data?.meta?.message, "success");
                    setPassword("");
                    setIsLoading(false)
                    props.hide()
                } else {
                    notify(response?.data?.meta?.message, "danger");
                    setIsLoading(false)
                    setPassword("");
                    props.hide()
                }
            },
            (error) => {
                notify(error?.response?.data?.meta?.message, "danger");
                setIsLoading(false)
                setPassword("");
                props.hide()
            }
        );
    }
    return (
        <Modal {...props} centered className="sucess-content">
            <Modal.Body className="sucess-modal">
                {isLoading && <Loader />}
                <div className="modal-container">
                    <img
                        src={crossImage}
                        alt=""
                        className="cross-img"
                        onClick={() => props.hide()}
                    />
                    <div className="moodal">
                        <div className="row">
                            <div className="col-md-12 login-text">
                                <CustomInput
                                    label="Change Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Change Password"
                                />
                            </div>
                        </div>
                        <CustomButton
                            children={"Submit"}
                            style={"mt-3"}
                            onClick={Approval}
                        />
                    </div>

                </div>

            </Modal.Body>
        </Modal>
    );
};

export default ChangePassword;
