import React from "react";
import Modal from 'react-bootstrap/Modal';
import './style.scss';
import crossImage from "../../../assets/images/close (4).png";

function ImageModal(props) {
    let base64 = props.base64;
    let fileType = props.fileType;

    return (
        <Modal
            {...props}
            centered
            size="lg"
        >
            <Modal.Body>
                <div className="modal-main-container">
                    <div className="imageModalContainer">
                        <div className="imageCard">
                            <div className="close-icon-container-image mb-3 d-flex">
                                <h5>{props.title}</h5>
                                <img
                                    title="Close"
                                    className="close-icon"
                                    src={crossImage}
                                    onClick={props.onHide}
                                />
                            </div>
                            {
                                (fileType === 'image/jpeg' || fileType === 'image/png' || fileType === 'image/jpg' || fileType === 'image/webp') ? (
                                    <img
                                        src={`data:${fileType};base64,${base64}`}
                                        className="imageBoxView"
                                    />
                                ) : (fileType === 'application/pdf') ? (
                                    <iframe
                                        height="315"
                                        width="100%"
                                        src={`data:application/pdf;base64,${base64}`}
                                        frameBorder="0"
                                        allowFullScreen
                                        className="imageBoxView"
                                    />
                                ) : (fileType === 'video/mp4') ? (
                                    <video
                                        width="100%"
                                        height="auto"
                                        controls
                                        autoPlay
                                        className="imageBoxView"
                                    >
                                        <source src={`data:${fileType};base64,${base64}`} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                ) : (fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ? (
                                    <iframe
                                        height="315"
                                        width="100%"
                                        src={`data:application/pdf;base64,${base64}`}
                                        frameBorder="0"
                                        allowFullScreen
                                        className="imageBoxView"
                                    />
                                ) : null
                            }
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
export default ImageModal;
