import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import BgIssuancePage from '../../src/pages/requestorJourney/BG/Issuance';
import LCIssuancePage from './../pages/requestorJourney/LC/Issuance';
import MissingRecieptForm from "../pages/requestorJourney/Imprest/MissingRecieptForm";
import PettyCashRequistion from "../pages/requestorJourney/Imprest/PettyCashRequisition";
import Sidebar from "../component/Sidebar";
import Dashboard from ".././pages/requestorJourney/dashboard/dashboard";
import Login from "../pages/auth/login";
import AJDashboard from '../pages/approverJourney/dashboard/dashboard';
import EditBgIssuance from '../component/bgIssuance/EditbgIssuance';
import TJDashboard from '../pages/treasuryJourney/dashboard/dashboard';
import ApproverbgIssuance from "../component/approverJourney/aprrovalBgIssuance"
import CreateBgAmendment from "../component/bgIssuance/createBgAmendment"
import CreateLcAmendment from "../component/LcPages/createLcAmendment"
import CreateBgCancellation from "../component/bgIssuance/createBgCancellation"
import CreateLcCancellation from "../component/LcPages/createLcCancellation"
import TreasuryJourneyIssue from '../component/TreasuryJourney/treasuryJourneyBgIssue';
import BgclosedAmendment from "../component/RJMyRequest/bgAmendemntIssue"
import BgclosedCancellation from "../component/RJMyRequest/bgCancellationIssue"
import LcClosedAmendment from "../component/RJMyRequest/amendmentLcIssue"
import LcClosedCancellation from "../component/RJMyRequest/cancellationLcIssue"
import EditLcIssuance from "../component/LcPages/editLcIssuance"
import AprroverLcIssue from "../component/approverJourney/approverLcIssuance"
import TreasuryLcIssue from "../component/TreasuryJourney/treasuryJourneyLcIssue"
import ForgetPassword from '../pages/ForgetPassword';
import ListingDashboard from "../pages/listingDashboard"
import ErrorPage from '../pages/errorPage';


// ==============HR MODULES ROUTE ========================================= //


import ManPowerRequisition from '../pages/HrNewModule/manPowerRequisition';
import AddNewRequisition from '../pages/HrNewModule/addNewRequisition';
import ApproverRequisitionForm from '../pages/HrNewModule/approverRequisitionForm';
import EditManPowerRequisition from '../pages/HrNewModule/editManPowerRequisition';
import ViewManPowerRequisition from '../pages/HrNewModule/viewManPowerRequisition';
import RequisitionApproverDashboard from '../pages/HrNewModule/manPowerRequisitionApprover';
import HrPortalDashboard from '../pages/HrNewModule/HrPortalDashboard';
import GenerateApplicantLink from '../pages/HrNewModule/generateApplicantLink';
import CandidateApplicantLink from '../pages/HrNewModule/candidateApplicantForm';
import ScheduleInterview from '../pages/HrNewModule/scheduleInterviewCandidate';
import HrPortalApprover from '../pages/HrNewModule/hrPortalApprover';
import Home from '../pages/home';
import CrmDashboard from '../pages/CRMModule/EndUser/Dashboard';
import RaiseATicket from '../pages/CRMModule/EndUser/RaiseTicket';
import AddTickets from '../pages/CRMModule/EndUser/RaiseTicket/addTicket';
import ViewTickets from '../pages/CRMModule/EndUser/RaiseTicket/viewTicket';
import EditTickets from '../pages/CRMModule/EndUser/RaiseTicket/editTicket';
import InterviewerForm from '../pages/HrNewModule/interviewerForm';
import MyTicket from '../pages/CRMModule/EndUser/MyTicket';
import ViewMyTicket from '../pages/CRMModule/EndUser/MyTicket/ViewMyTicket';
import Notification from '../pages/notification';
import ViewparticularStatus from '../pages/CRMModule/EndUser/ParticularStatus';
import HistoryTicket from '../pages/CRMModule/EndUser/history';



const AppRoutes = () => {
  const fetchRole = sessionStorage.getItem("role")
  const role = fetchRole?.split(",")
  let treasuryArr = ["Requestor", "Approver", "Treasury"]
  let ManpowerReqArray = ["Manager", "HR", "Interviewer"]
  let ManPowerApprovalArray = ["HOD", "HR", "CHRO", "MD", "CEO", "Director", "CXO", "Segment HR"]
  let InterviewAppArray = ["Interviewer"]
  let InterviewApp = ["HR"]
  let crmArr = ["Inhouse consultant", "Outsourced consultant/Vendor", "Employee"]
  let cmrApprover = ["L2", "L3"]
  let sessions = sessionStorage.getItem("candidate")
  let session = JSON.parse(sessions)

  const isLoggedIn = sessionStorage.getItem('isAuth') === "true" ? true : false;
  const candidate = sessionStorage.getItem("candidate")
  if (isLoggedIn) {
    return (
      <Router>
        <Sidebar />
        <Routes>

          {!role.includes("undefined") && <Route path={`/home`} element={<Home />} />}
          {role.some(element => treasuryArr.includes(element)) &&
            <React.Fragment>
              <Route path={`/requestor-bg-issuance`} element={<BgIssuancePage />} />
              <Route path={`/requestor-lc-issuance`} element={<LCIssuancePage />} />
              <Route path={`/requestor-imprest-missing-reciept`} element={<MissingRecieptForm />} />
              <Route path={`/requestor-imprest-petty-cash-requistion`} element={<PettyCashRequistion />} />
              <Route path={"/create-bg-amendment"} element={<CreateBgAmendment />} />
              <Route path={"/create-bg-cancellation"} element={<CreateBgCancellation />} />
              <Route path={"/create-lc-amendment"} element={<CreateLcAmendment />} />
              <Route path={"/create-lc-cancellation"} element={<CreateLcCancellation />} />
              <Route path={`/edit-bg-issuance`} element={<EditBgIssuance />} />
              <Route path={`/edit-lc-issuance`} element={<EditLcIssuance />} />
              <Route path={`/closed-bg-amendment`} element={<BgclosedAmendment />} />
              <Route path={`/closed-bg-cancellation`} element={<BgclosedCancellation />} />
              <Route path={`/closed-lc-amendment`} element={<LcClosedAmendment />} />
              <Route path={`/closed-lc-cancellation`} element={<LcClosedCancellation />} />
              <Route path={"/approver-bg-issue"} element={<ApproverbgIssuance />} />
              <Route path={"/approver-lc-issue"} element={<AprroverLcIssue />} />
              <Route path={`/requestor-journey-dashboard`} element={<Dashboard />} />
              <Route path={`/treasury-journey-dashboard`} element={<TJDashboard />} />
              <Route path={"/treasury-bg-issue"} element={<TreasuryJourneyIssue />} />
              <Route path={"/treasury-lc-issue"} element={<TreasuryLcIssue />} />
              <Route path={`/approver-journey-dashboard`} element={<AJDashboard />} />
              <Route path={`/listing-dashboard`} element={<ListingDashboard />} />
            </React.Fragment>
          }

          {/*==============HR MODULES ROUTE =========================================*/}

          {role.some(element => ManpowerReqArray.includes(element)) &&
            <React.Fragment>
              <Route path={`/manpower-requisition-dashboard`} element={<ManPowerRequisition />} />
              <Route path={`/add-new-requisition`} element={<AddNewRequisition />} />
              <Route path={`/view-requisition-form`} element={<ViewManPowerRequisition />} />
              <Route path={`/edit-requisition-form`} element={<EditManPowerRequisition />} />
            </React.Fragment>
          }

          {role.some(element => ManPowerApprovalArray.includes(element)) &&
            <React.Fragment>
              <Route path={`/approver-requisition-dashboard`} element={<RequisitionApproverDashboard />} />
              <Route path={`/approver-requisition-form`} element={<ApproverRequisitionForm />} />
            </React.Fragment>
          }

          {role.some(element => InterviewAppArray.includes(element)) &&
            <React.Fragment>
              <Route path={`/hr-portal-approver`} element={<HrPortalApprover />} />
              <Route path={`/interviwer-from`} element={<InterviewerForm />} />
            </React.Fragment>
          }
          {role.some(element => InterviewApp.includes(element)) &&
            <React.Fragment>
              <Route path={`/hr-portal-dashboard`} element={<HrPortalDashboard />} />
              <Route path={`/generate-applicant-link`} element={<GenerateApplicantLink />} />
              <Route path={`/schedule-applicant-interview`} element={<ScheduleInterview />} />
            </React.Fragment>
          }
          <Route path={`/candidate-applicant-form`} element={<CandidateApplicantLink />} />

          {/****==============CRM MODULES ROUTE =========================================****/}


          {(role.some(element => crmArr.includes(element)) || session?.support_levels?.some(element => cmrApprover.includes(element))) &&
            <>
              <Route path={`/Dashboard`} element={<CrmDashboard />} />
              <Route path={`/raise-ticket`} element={<RaiseATicket />} />
              <Route path={`/add-ticket`} element={<AddTickets />} />
              <Route path={`/view-ticket`} element={<ViewTickets />} />
              <Route path={`/view-my-ticket`} element={<ViewMyTicket />} />
              <Route path={`/edit-ticket-end-user`} element={<EditTickets />} />
              <Route path={`/my-ticket`} element={<MyTicket />} />
              <Route path={`/view-complaint-status`} element={<ViewparticularStatus />} />
              <Route path={`/history-ticket`} element={<HistoryTicket />} />
            </>
          }
          <Route  path="/notification" element={<Notification />} />
          <Route path={`*`} element={<ErrorPage />} />

        </Routes>
      </Router>

    )
  } else {
    return (
      <Router>
        <Routes>
          <Route path={`/`} element={<Login />} />
          <Route path={`/password-reset/:userId/:token`} element={<ForgetPassword />} />
          <Route path={'*'} element={<Navigate replace to={`/`} />} />
        </Routes>
      </Router>
    )
  }
};

export default AppRoutes;
