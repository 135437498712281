import React from 'react';
import './style.scss';
 
function CustomeInputForm({ label, fromplaceholder,fromvalue,fromonChange,type, toplaceholder, tovalue,toonChange, readOnly, mandatory }) {
    return (
        <div className="midal_input_form">
            <label>{label}
                {mandatory &&
                    <span>*</span>
                }
            </label>
            <div className='input_form'>
                <input
                   type={type}
                    placeholder={fromplaceholder}
                    value={fromvalue}
                    onChange={fromonChange}
                    readOnly={readOnly}
                />
                -
                <input
                   type={type}
                    placeholder={toplaceholder}
                    value={tovalue}
                    onChange={toonChange}
                    readOnly={readOnly}
                />
            </div>
        </div>
    )
}
 
export default CustomeInputForm;