import React, { useState } from 'react';
import './style.scss';

function Textarea(props) {
    const { style, title, placeholder, readOnly, onChange, textvalue } = props
    const [isTyping, setIsTyping] = useState(false);

    const handleFocus = () => {
        setIsTyping(true);
    };

    const handleBlur = () => {
        setIsTyping(false);
    }

    return (
        <div className='textarea_main_section'>
            <label className='input_label'>{props.label}{props.require && <span className="text-danger">*</span>}</label>
            <textarea
                className={[`textarea ${style}`]}
                id="textarea" name="textarea"
                readOnly={readOnly}
                placeholder={placeholder}
                onChange={onChange}
                style={{ backgroundColor: readOnly ? '#F0EFEF' : 'white', textTransform: isTyping ? 'none' : 'capitalize', }}
                value={textvalue}
                onFocus={handleFocus}
                onBlur={handleBlur}

            >
                {title}
            </textarea>
        </div>
    )
}

export default Textarea;
