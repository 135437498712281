import { makeAutoObservable } from "mobx";
import Service from "../shared/service/service";
import { error } from "jquery";

class crmStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)
    }

    async createRequisition(formData, callback, callbackerror) {
        await Service.post("hr/createManRequisitionForm", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getAllBusinessList(formData, callback, callbackerror) {
        await Service.getApi(`businesses`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getDesignations(formData, callback, callbackerror) {
        await Service.getApi(`designations`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }
    async getDeparments(formData, callback, callbackerror) {
        await Service.getApi(`departments`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getAllCompanyPlant(payload, callback, callbackerror) {
        await Service.post("getPlantByComapnyId", payload, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }


    async getAllCompanyPlantLocation(payload, callback, callbackerror) {
        await Service.post("getPlantsLocationByPlantId", payload, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }

    async getAllManRequsition(payload, callback, callbackerror) {
        await Service.post("hr/fetch_allManRequisitionForms", payload, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }

    async updateManRequistion(id, formData, callback, callbackerror) {
        await Service.post(`hr/updateManRequisitionForm/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }


    async approveManPowerForm(id, formData, callback, callbackerror) {
        await Service.post(`hr/approveRequisition/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async auditTailManpower(id, formData, callback, callbackerror) {
        await Service.getApi(`hr/approval_audit/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async sendCandateUrl(formData, callback, callbackerror) {
        await Service.post(`hr/sendCandidateApplicationLink`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }
    async candidateForm(id, formData, callback, callbackerror) {
        await Service.post(`hr/insertCandidateDetails/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getAllCandidateForm(formData, callback, callbackerror) {
        await Service.post(`hr/getCandidateDetails`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async interviewer(formData, callback, callbackerror) {
        await Service.getApi(`hr/interviewer?getInterviewersList=true`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async scheduleInterview(id, formData, callback, callbackerror) {
        await Service.post(`hr/candidate_remark/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async InterviewStatus(id, formData, callback, callbackerror) {
        await Service.post(`hr/candidate_interviewer_feedback/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }
    // async getEmployeeByNusiness(id ,formData, callback, callbackerror) {
    //     await Service.getApi(`user/users_by_business/${id}`, formData, async (response) => {
    //         return callback(response)
    //     }).catch((error) => {
    //         return callbackerror(error);
    //     })
    // }
    async getEmployeeByNusiness(id, formData, callback, callbackerror) {
        await Service.getApi(`users_by_business/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getAllPosition(formData, callback, callbackerror) {
        await Service.get(`getPositionsNoFilters`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getAllCategories(formData, callback, callbackerror) {
        await Service.get(`categories`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    // async getAllSubcategories(formData, callback, callbackerror) {
    //     await Service.postAdmin(`all_subcategories`, formData, async (response) => {
    //         return callback(response)
    //     }).catch((error) => {
    //         return callbackerror(error);
    //     })
    // }
    async getAllSubcategories(formData, callback, callbackerror) {
        await Service.postAdmin("filter_multiple_subcategories", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async createComplaint(formData, callback, callbackerror) {
        await Service.post(`cms/complaint/create`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getPriorityNoFilters(formData, callback, callbackerror) {
        await Service.get(`getPriorityNoFilters`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getIssueTypeNoFilters(formData, callback, callbackerror) {
        await Service.get(`getIssueTypeNoFilters`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getAllCmsComplaints(formData, callback, callbackerror) {
        await Service.post1(`cms/complaint/all`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async sendToSegment(id, formData, callback, callbackerror) {
        await Service.post(`hr/forward_requisition/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getSegment(formData, callback, callbackerror) {
        await Service.getApi(`hrlist`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async auditTrailCms(id, formData, callback, callbackerror) {
        await Service.getApi(`cms/complaint/audit/trail/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async addTdDetails(id, formData, callback, callbackerror) {
        await Service.post(`cms/complaint/addTRDetails/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async acknowledgeTicket(formData, callback, callbackerror) {
        await Service.post(`cms/complaint/accept`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }

    async getCompById(id, formData, callback, callbackerror) {
        await Service.getApi(`cms/complaint/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async addComment(id, formData, callback, callbackerror) {
        await Service.post(`cms/complaint/comment/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async editComplaint(id, formData, callback, callbackerror) {
        await Service.post(`cms/complaint/reporter/edit/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getCnadDetailsId(id, formData, callback, callbackerror) {
        await Service.getApi(`hr/candidates/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getAuditInterview(id, formData, callback, callbackerror) {
        await Service.getApi(`hr/candidates/audit/trail/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getStatus(formData, callback, callbackerror) {
        await Service.get(`complaint_status`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }
    async getStatusForInhouse(formData, callback, callbackerror) {
        await Service.get(`complaint_status?isInhouse=true`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async updateComplaintStatus(id, formData, callback, callbackerror) {
        await Service.post(`cms/complaint/consultant/L1/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }
    async getApprovals(id, formData, callback, callbackerror) {
        await Service.getApi(`cms/complaints/all/approvers?support_level=${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }
    async getOutsourceCons(id, formData, callback, callbackerror) {
        await Service.getApi(`cms/complaints/outsource/consultant`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async sendToL1(id, formData, callback, callbackerror) {
        await Service.post(`cms/complaint/outsource/send/approval/L1/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async acceptRejectL2(id, formData, callback, callbackerror) {
        await Service.post(`cms/complaint/consultant/L2/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }


    async getNotifications(formData, callback, callbackerror) {
        await Service.post('notifications', formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }

    async getCount(formData, callback, callbackerror) {
        await Service.getApi(`getNotificationCount`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }
    async updateCount(id ,formData, callback, callbackerror) {
        await Service.getApi(`updateNotificationStatus/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }
    async acceptRejectL3(id, formData, callback, callbackerror) {
        await Service.post(`cms/complaint/consultant/L3/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }

    async getDashboardCount(formData, callback, callbackerror) {
        await Service.post(`cms/complaints/all/status/count`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async markUatCompleted(id, formData, callback, callbackerror) {
        await Service.post(`cms/complaint/reporter/isUATCompleted/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }
    
    async cancelEmpTicket(id , formData, callback, callbackerror) {
        await Service.post(`cms/complaint/reporter/cancelled/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }
    async historyTicket(id , formData, callback, callbackerror) {
        await Service.post(`cms/complaint/assignee/history/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    
    async uploadfile(formData, callback, callbackerror) {
        await Service.postUpload(`uploadFile/cmtAttachment'`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async downloadImage(formData, callback, callbackerror) {
        await Service.post("downloadFile", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async removedImage(formData, callback, callbackerror) {
        await Service.post("deleteFile", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }
}
export default crmStore;