import React, { useState, useEffect } from "react";
import Header from "../../../../component/Header";
// import "./style.scss";
import Loader from "../../../../component/helper/Loader/loader";
import { useNavigate, useLocation } from "react-router-dom";
import { useStores } from "../../../../stores";
import notify from "../../../../shared/notify";
// import ComplaintDashboard from "../../../../component/CRMModuleComponent/complaintDashboard";
import ParticularDashboard from "../../../../component/CRMModuleComponent/particularDashboard";

const limit = [
    { option: 10, value: 10, },
    { option: 100, value: 100, },
    { option: 500, value: 500, },
    { option: 1000, value: 1000, },
];

const ViewparticularStatus = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [complaintData, setComplaintData] = useState([]);
    const [allComplainData, setAllComplainData] = useState([]);
    const navigate = useNavigate();
    const { crmStore } = useStores();
    const { state } = useLocation();
    const [page, setPage] = useState(parseInt(sessionStorage.getItem('currentPageStatus')) || 1);
    const [pageLimit, setPageLimit] = useState(parseInt(sessionStorage.getItem('pageLimitStatus')) || 10);
    const [categoryList, setCategoryList] = useState([]);
    const [selectCategory, setSelectCategory] = useState(sessionStorage.getItem('selectedCategoryStatus') || "");
    const [priorityList, setPriorityList] = useState([]);
    const [selectedPriority, setSelectedPriority] = useState(sessionStorage.getItem('selectedPriorityStatus') || "");
    const [toDate, setToDate] = useState(sessionStorage.getItem('toDateStatus') || "")
    const [fromDate, setFromDate] = useState(sessionStorage.getItem('fromDateStatus') || "")
    const [complaintStatusList, setComplaintStatusList] = useState([])
    const [selectedComplaintStatus, setSelectedComplaintStatus] = useState(sessionStorage.getItem('selectedComplaintStatusParticular') || "")
    let fetchRole = sessionStorage.getItem("role");
    const role = fetchRole?.split(",");
    let sessions = sessionStorage.getItem("candidate");
    let session = JSON.parse(sessions);
    let crmApprover = ["L2", "L3"]

    useEffect(() => {
        fetchAllCategories();
        getPriorities();
        CallFetchedStatus()
        const storedCategory = sessionStorage.getItem('selectedCategoryStatus');
        const storedPriority = sessionStorage.getItem('selectedPriorityStatus');
        const storedtoDate = sessionStorage.getItem('toDateStatus');
        const storedfromDate = sessionStorage.getItem('fromDateStatus');
        const storedselectedComplaintStatus = sessionStorage.getItem('selectedComplaintStatusParticular');

        if (storedCategory) setSelectCategory(storedCategory);
        if (storedPriority) setSelectedPriority(storedPriority);
        if (storedtoDate) setToDate(storedtoDate);
        if (storedfromDate) setFromDate(storedfromDate);
        if (storedselectedComplaintStatus) setSelectedComplaintStatus(storedselectedComplaintStatus);


        FetchedComplain();
    }, [pageLimit, page]);

    const FetchedComplain = async () => {
        let payload = {
            limit: pageLimit,
            filters: {},
            pageNumber: page,
        };

        if ((role?.includes("Inhouse consultant")) || (role?.includes("Outsourced consultant/Vendor"))
            || (session?.support_levels?.some(element => crmApprover.includes(element)))) {
            payload.filters.agent_id = session?.id
        }
        if ((role?.includes("Employee"))) {
            payload.filters.reporter_id = session.id
        }
        if (selectCategory) {
            payload.filters.category_id = selectCategory;
        }
        if (selectedPriority) {
            payload.filters.priority_id = selectedPriority;
        }
        if (toDate) {
            payload.filters.to_date = toDate;
        }
        if (fromDate) {
            payload.filters.from_date = fromDate;
        }

        if (state?.item == "inprogress") {
            payload.filters.complaint_status_id = 2;
        } else if (state?.item == "closed") {
            payload.filters.complaint_status_id = 5;

        } else if (state?.item == "open") {
            payload.filters.complaint_status_id = 1;

        }

        CallFetchedComplain(payload);
    };

    const CallFetchedStatus = async () => {
        await crmStore.getStatus((status, res) => {
            if (res?.meta?.status === "success") {
                setComplaintStatusList(res.data.complaintStatuses);
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger");
        });
    }

    const CallFetchedComplain = async (payload) => {
        setIsLoading(true);
        await crmStore.getAllCmsComplaints(
            payload,
            (res) => {
                if (res?.data?.meta?.status === "success") {
                    setComplaintData(res?.data?.data?.filteredData?.results);
                    setAllComplainData(res?.data?.data);
                    setIsLoading(false);
                }
            },
            (err) => {
                notify(err?.response?.data?.meta?.message, "danger");
                setIsLoading(false);
            }
        );
    };

    const handlePageSubmitt = async (index) => {
        const newPage = index.selected + 1;
        setPage(newPage);
        sessionStorage.setItem('currentPageStatus', newPage);
        let payload = {
            limit: pageLimit,
            filters: {},
            pageNumber: newPage,
        };

        if ((role?.includes("Inhouse consultant")) || (role?.includes("Outsourced consultant/Vendor"))
            || (session?.support_levels?.some(element => crmApprover.includes(element)))) {
            payload.filters.agent_id = session?.id
        }
        if ((role?.includes("Employee"))) {
            payload.filters.reporter_id = session.id
        }
        if (selectCategory) {
            payload.filters.category_id = selectCategory;
        }
        if (selectedPriority) {
            payload.filters.priority_id = selectedPriority;
        }
        if (toDate) {
            payload.filters.to_date = toDate;
        }
        if (fromDate) {
            payload.filters.from_date = fromDate;
        }

        if (state?.item == "inprogress") {
            payload.filters.complaint_status_id = 2;
        } else if (state?.item == "closed") {
            payload.filters.complaint_status_id = 5;

        } else if (state?.item == "open") {
            payload.filters.complaint_status_id = 1;

        }

        CallFetchedComplain(payload);
    };

    const limitChangeHandlerr = async (index) => {
        setPageLimit(index);
        sessionStorage.setItem('pageLimitStatus', index);
        let payload = {
            limit: index,
            filters: {},
            pageNumber: page,
        };

        if ((role?.includes("Inhouse consultant")) || (role?.includes("Outsourced consultant/Vendor"))
            || (session?.support_levels?.some(element => crmApprover.includes(element)))) {
            payload.filters.agent_id = session?.id
        }
        if ((role?.includes("Employee"))) {
            payload.filters.reporter_id = session.id
        }
        if (selectCategory) {
            payload.filters.category_id = selectCategory;
        }
        if (selectedPriority) {
            payload.filters.priority_id = selectedPriority;
        }
        if (toDate) {
            payload.filters.to_date = toDate;
        }
        if (fromDate) {
            payload.filters.from_date = fromDate;
        }

        if (state?.item == "inprogress") {
            payload.filters.complaint_status_id = 2;
        } else if (state?.item == "closed") {
            payload.filters.complaint_status_id = 5;

        } else if (state?.item == "open") {
            payload.filters.complaint_status_id = 1;

        }

        CallFetchedComplain(payload);
    };

    const handleFilterr = async () => {
        sessionStorage.setItem('selectedCategoryStatus', selectCategory);
        sessionStorage.setItem('selectedPriorityStatus', selectedPriority);
        sessionStorage.setItem('toDateStatus', toDate);
        sessionStorage.setItem('fromDateStatus', fromDate);
        sessionStorage.setItem('selectedComplaintStatusParticular', selectedComplaintStatus);

        FetchedComplain();
    };

    const handleResett = async () => {
        setSelectCategory("");
        setSelectedPriority("");
        setToDate("")
        setFromDate("")
        // setSelectedComplaintStatus("")
        setPage(1);
        setPageLimit(10);

        sessionStorage.removeItem('selectedCategoryStatus');
        sessionStorage.removeItem('selectedPriorityStatus');
        sessionStorage.removeItem('toDateStatus');
        sessionStorage.removeItem('fromDateStatus');
        sessionStorage.removeItem('selectedComplaintStatusParticular');
        sessionStorage.removeItem('currentPageStatus');
        sessionStorage.removeItem('pageLimitStatus');

        let payload = {
            limit: 10,
            filters: {},
            pageNumber: 1,
        };
        if ((role?.includes("Inhouse consultant")) || (role?.includes("Outsourced consultant/Vendor"))
            || (session?.support_levels?.some(element => crmApprover.includes(element)))) {
            payload.filters.agent_id = session?.id
        }
        if ((role?.includes("Employee"))) {
            payload.filters.reporter_id = session.id
        }
        if (state?.item == "inprogress") {
            payload.filters.complaint_status_id = 2;
        } else if (state?.item == "closed") {
            payload.filters.complaint_status_id = 5;

        } else if (state?.item == "open") {
            payload.filters.complaint_status_id = 1;

        }

        CallFetchedComplain(payload);
    };

    const fetchAllCategories = () => {
        crmStore.getAllCategories(
            (status, res) => {
                if (res?.meta?.status === "success") {
                    setCategoryList(res.data.categories);
                }
            },
            (error) => {
                notify(error?.response?.data?.meta?.message, "danger");
            }
        );
    };

    const getPriorities = () => {
        crmStore.getPriorityNoFilters((status, res) => {
            if (res?.meta?.status === "success") {
                setPriorityList(res?.data?.getAllComplaintPriorityLevels);
            } else {
                console.log(res?.data?.meta?.message, "setDeparmentList");
            }
        });
    };

    return (
        <div className="main-container raiseTicket_container_crm">
            {isLoading && <Loader />}
            <div className="right-view">
                <Header title={"Tickets,"} />
                <div className="container-fluid px-0">
                    <ParticularDashboard
                        allComplainData={allComplainData}
                        complaintData={complaintData}
                        handlePageSubmit={handlePageSubmitt}
                        page={page}
                        pageLimit={pageLimit}
                        limit={limit}
                        limitChangeHandler={limitChangeHandlerr}
                        handleFilter={handleFilterr}
                        handleReset={handleResett}
                        handleView={(item) => navigate('/view-ticket', { state: { item } })}
                        handleEdit={(item) => navigate('/edit-ticket-end-user', { state: { item } })}
                        categoryList={categoryList}
                        selectCategory={selectCategory}
                        handleSelectCategory={(e) => setSelectCategory(e)}
                        handlePriority={(e) => setSelectedPriority(e)}
                        selectedPriority={selectedPriority}
                        priorityList={priorityList}
                        FetchedComplain={FetchedComplain}
                        handleToDate={(e) => setToDate(e)}
                        handleFromDate={(e) => setFromDate(e)}
                        toDate={toDate}
                        fromDate={fromDate}
                        complaintStatusList={complaintStatusList}
                        handleSelectStatus={(e) => setSelectedComplaintStatus(e)}
                        selectedComplaintStatus={selectedComplaintStatus}
                    />
                </div>
            </div>
        </div>
    );
};

export default ViewparticularStatus;
