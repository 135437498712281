import React, { useEffect, useState } from "react";
import Header from "../../../../component/Header";
import CustomCard from "../../../../component/CustomeCard";
import "./style.scss";
import GraphChart from "../../../../component/GraphChart";
import Customselect from "../../../../component/CustomSelect";
import ApproverRoleCard from "../../../../component/ApproverRoleCard";
import Loader from "../../../../component/helper/Loader/loader";
import AllTicketsChart from "../../../../component/GraphChart/allTicketGraph";
import { useNavigate } from "react-router-dom";
import { useStores } from "../../../../stores";
import notify from "../../../../shared/notify";
import ComplaintDashboard from "../../../../component/CRMModuleComponent/complaintDashboard";



const CrmDashboard = () => {
    const [lcChartShowType, setLCChartShowType] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [complaintData, setComplaintData] = useState([])
    const [allComplainData, setAllComplainData] = useState([])
    const navigate = useNavigate();
    const { mainStore, crmStore } = useStores()
    const [page, setPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(10)
    let fetchRole = sessionStorage.getItem("role")
    const role = fetchRole?.split(",")
    let sessions = sessionStorage.getItem("candidate")
    let session = JSON.parse(sessions)
    let crmApprover = ["L2", "L3"]
    const [dashboardData, setDashboardData] = useState("")

    const options = [
        { label: "Percentage Data", value: "percentage" },
        { label: "Number Data", value: "value" },
    ];

    const ticketData = [

        ["Task", "each Instrument "],
        [`Open ${Number(dashboardData?.openComplaints)}`, Number(dashboardData?.openComplaints)],
        [`Closed ${Number(dashboardData?.closedComplaints)}`, Number(dashboardData?.closedComplaints)],
        [`WIP ${Number(dashboardData?.inProgressComplaints)}`, Number(dashboardData?.inProgressComplaints)],
        [`Cancelled ${Number(dashboardData?.cancelledComplaints)}`, Number(dashboardData?.cancelledComplaints)],
        [`UAT Completed ${Number(dashboardData?.uatCompletedComplaints)}`, Number(dashboardData?.uatCompletedComplaints)],
        [`UAT Pending ${Number(dashboardData?.uatPendingComplaints)}`, Number(dashboardData?.uatPendingComplaints)],
    ];

    const limit = [
        {
            option: 10,
            value: 10,
        },
        {
            option: 100,
            value: 100,
        },
        {
            option: 500,
            value: 500,
        },
        {
            option: 1000,
            value: 1000,
        },
    ];


    const ticketOptions = {
        slices: {
            0: { color: "#a3b18a" },
            1: { color: "#3a5a40" },
            2: { color: "#e76f51" },
            3: { color: "#e65b5b" },
            4: { color: "#699c69" },
            5: { color: "#a8a83d" },

        },
        chartArea: { width: 400, height: 400 },
        pieSliceText: lcChartShowType,
        sliceVisibilityThreshold: 0,
    };

    useEffect(() => {
        FetchedComplain()
    }, [])

    const FetchedComplain = async () => {
        let payload = {
            limit: pageLimit,
            filters: {
            },
            pageNumber: page
        }
        if ((role?.includes("Inhouse consultant")) || (role?.includes("Outsourced consultant/Vendor"))
            || (session?.support_levels?.some(element => crmApprover.includes(element)))) {
            payload.filters.agent_id = session?.id
        }
        if ((role?.includes("Employee"))) {
            payload.filters.reporter_id = session.id
        }
        CallFetchedComplain(payload)

    }

    const CallFetchedComplain = async (payload) => {
        setIsLoading(true)
        await crmStore.getAllCmsComplaints(payload, (res) => {
            if (res?.data?.meta?.status === "success") {
                setComplaintData(res?.data?.data?.filteredData?.results?.slice(0, 4))
                setAllComplainData(res?.data?.data)
                setIsLoading(false)
            }

        }, (err) => {
            notify(err?.response?.data?.meta?.message, "danger")
            setIsLoading(false)
        })
    }

    const handlePageSubmitt = async (index) => {
        setPage(index.selected + 1);
        let payload = {
            limit: pageLimit,
            filters: {},
            pageNumber: index.selected + 1,
        };
        if ((role?.includes("Inhouse consultant")) || (role?.includes("Outsourced consultant/Vendor"))
            || (session?.support_levels?.some(element => crmApprover.includes(element)))) {
            payload.filters.agent_id = session?.id
        }
        if ((role?.includes("Employee"))) {
            payload.filters.reporter_id = session.id
        }
        CallFetchedComplain(payload);
    };

    const limitChangeHandlerr = async (index) => {
        setPageLimit(index);
        let payload = {
            limit: index,
            filters: {},
            pageNumber: page,
        };
        if ((role?.includes("Inhouse consultant")) || (role?.includes("Outsourced consultant/Vendor"))
            || (session?.support_levels?.some(element => crmApprover.includes(element)))) {
            payload.filters.agent_id = session?.id
        }
        if ((role?.includes("Employee"))) {
            payload.filters.reporter_id = session.id
        }
        CallFetchedComplain(payload);
    };

    const handleFilterr = async () => {
        let payload = {
            limit: pageLimit,
            filters: {
            },
            pageNumber: page,
        };
        if ((role?.includes("Inhouse consultant")) || (role?.includes("Outsourced consultant/Vendor"))
            || (session?.support_levels?.some(element => crmApprover.includes(element)))) {
            payload.filters.agent_id = session?.id
        }
        if ((role?.includes("Employee"))) {
            payload.filters.reporter_id = session.id
        }
        CallFetchedComplain(payload);
    };

    const handleResett = async () => {
        setPage(1);
        setPageLimit(10);
        await FetchedComplain();
    };

    useEffect(() => {
        fetDashboardData()
    }, [])

    const fetDashboardData = async () => {
        let payload = {
            filters: {},
        }
        if ((role?.includes("Employee"))) {
            payload.filters.reporter_id = session.id
        } else {
            payload.filters.agent_id = session.id
        }
        await crmStore.getDashboardCount(payload, (res) => {
            if (res?.data?.meta?.status === "success") {
                setDashboardData(res?.data?.data)
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
        })
    }

    const data1 = [
        ["",
            `Open ${Number(dashboardData?.openComplaints)}`,
            ` Closed ${Number(dashboardData?.closedComplaints)}`,
            ` WIP ${Number(dashboardData?.inProgressComplaints)}`,
            ` Cancelled ${Number(dashboardData?.cancelledComplaints)}`,
            ` UAT Completed  ${Number(dashboardData?.uatCompletedComplaints)}`,
            ` UAT Pending ${Number(dashboardData?.uatPendingComplaints)}`,
        ],

        ['Tickets',
            Number(dashboardData?.openComplaints),
            Number(dashboardData?.closedComplaints),
            Number(dashboardData?.inProgressComplaints),
            Number(dashboardData?.cancelledComplaints),
            Number(dashboardData?.uatCompletedComplaints),
            Number(dashboardData?.uatPendingComplaints)
        ],
    ];

    const optionsTicket = {
        colors: ['#A3B18A', "#395A3F", '#E66F51', "#e65b5b", "#699c69", "#a8a83d"],
    };


    return (
        <div className="main-container dashboard_container_crm">
            {isLoading && <Loader />}
            <div className="right-view">
                <Header title={"User Dashboard,"} />
                <div className="container-fluid px-0">
                    <div className="row">
                        <div className="col-md-3" onClick={() => {
                            sessionStorage.setItem("selectedComplaintStatusParticular", "")
                            sessionStorage.setItem("selectedPriorityStatus", "")
                            sessionStorage.setItem("selectedCategoryStatus", "")
                            sessionStorage.setItem("pageLimitStatus", 10)
                            sessionStorage.setItem("currentPageStatus", 1)
                            sessionStorage.setItem('fromDateStatus', "")
                            sessionStorage.setItem('toDateStatus', "")
                            navigate('/view-complaint-status', { state: { item: "total" } })
                        }}>
                            <ApproverRoleCard
                                src={require("../../../../assets/images/list.png")}
                                role={"Total Tickets"}
                                requests={dashboardData?.totalComplaints}
                            />
                        </div>
                        <div className="col-md-3" onClick={() => {
                            sessionStorage.setItem("selectedComplaintStatusParticular", "")
                            sessionStorage.setItem("selectedPriorityStatus", "")
                            sessionStorage.setItem("selectedCategoryStatus", "")
                            sessionStorage.setItem("pageLimitStatus", 10)
                            sessionStorage.setItem("currentPageStatus", 1)
                            sessionStorage.setItem('fromDateStatus', "")
                            sessionStorage.setItem('toDateStatus', "")
                            navigate('/view-complaint-status', { state: { item: "open" } })
                        }}>
                            <ApproverRoleCard
                                src={require("../../../../assets/images/pinklist.png")}
                                role={"Open Tickets"}
                                requests={dashboardData?.openComplaints}
                            />
                        </div>
                        <div className="col-md-3" onClick={() => {
                            sessionStorage.setItem("selectedComplaintStatusParticular", "")
                            sessionStorage.setItem("selectedPriorityStatus", "")
                            sessionStorage.setItem("selectedCategoryStatus", "")
                            sessionStorage.setItem("pageLimitStatus", 10)
                            sessionStorage.setItem("currentPageStatus", 1)
                            sessionStorage.setItem('fromDateStatus', "")
                            sessionStorage.setItem('toDateStatus', "")
                            navigate('/view-complaint-status', { state: { item: "inprogress" } })
                        }}>
                            <ApproverRoleCard
                                src={require("../../../../assets/images/ticket-icon1.png")}
                                role={"WIP Tickets"}
                                requests={dashboardData?.inProgressComplaints}
                            />
                        </div>
                        <div className="col-md-3" onClick={() => {
                            sessionStorage.setItem("selectedComplaintStatusParticular", "")
                            sessionStorage.setItem("selectedPriorityStatus", "")
                            sessionStorage.setItem("selectedCategoryStatus", "")
                            sessionStorage.setItem("pageLimitStatus", 10)
                            sessionStorage.setItem("currentPageStatus", 1)
                            sessionStorage.setItem('fromDateStatus', "")
                            sessionStorage.setItem('toDateStatus', "")
                            navigate('/view-complaint-status', { state: { item: "closed" } })
                        }}>
                            <ApproverRoleCard
                                src={require("../../../../assets/images/ticket-icon.png")}
                                role={"Closed Tickets"}
                                requests={dashboardData?.closedComplaints}
                            />
                        </div>

                        <div className="col-md-6">
                            <CustomCard
                                cardboldtitle={"PI Distribution"}
                                toprightview={<Customselect
                                    onChange={(e) => {
                                        setLCChartShowType(e.target.value);
                                    }}
                                    blankLabel={true}
                                    optionview={options.map((option) => {
                                        return (
                                            <option value={option.value}>{option.label}</option>
                                        );
                                    })} />}
                                cardbody={
                                    <GraphChart data={ticketData} options={ticketOptions} />
                                }
                            />
                        </div>


                        <div className="col-md-6">
                            <CustomCard
                                cardboldtitle={"Bar Distribution"}

                                cardbody={
                                    <AllTicketsChart data={data1} options={optionsTicket} />
                                }
                            />
                        </div>

                        <div className="col-md-12">
                            <ComplaintDashboard
                                allComplainData={allComplainData}
                                complaintData={complaintData}
                                handlePageSubmit={handlePageSubmitt}
                                page={page}
                                pageLimit={pageLimit}
                                limit={limit}
                                limitChangeHandler={limitChangeHandlerr}
                                handleFilter={handleFilterr}
                                handleReset={handleResett}
                                handleView={(item) => navigate('/view-ticket', { state: { item } })}
                                handleEdit={(item) => navigate('/edit-ticket-end-user', { state: { item } })}
                            />
                        </div>

                    </div>
                </div>
            </div>
        </div >
    );
};

export default CrmDashboard;
