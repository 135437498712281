import React, { useState, useEffect } from "react";
import Header from "../../../../component/Header";
import CustomCard from "../../../../component/CustomeCard";
import "./style.scss";
import CustomButton from "../../../../component/Button/Button";
import CustomInput from "../../../../component/CustomInput";
import Customselect from "../../../../component/CustomSelect";
import TextEditor from "../../../../component/CustomeTextEditior";
import Textarea from "../../../../component/CustomeTextarea";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import commentImg from '../../../../assets/images/comment-img.png';
import commentImg1 from '../../../../assets/images/comment1-img.png';
import SubmitTicketModal from "../../../../component/CRMModuleComponent/successModal";
import AddTRNumberModal from "../../../../component/CRMModuleComponent/addTRNumberModal";
import commentIcon from '../../../../assets/images/send-comment.png';
import { useLocation, useNavigate } from "react-router-dom"
import { useStores } from "../../../../stores";
import ReactExport from "react-export-excel";
import notify from "../../../../shared/notify";
import FormatDate from "../../../../component/helper/formatDate/formateDate";
import Loader from "../../../../component/helper/Loader/loader";
import { Store } from "react-notifications-component";
import ImageModal from "../../../../component/CRMModuleComponent/ImageModal/Index";



const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const EditTickets = () => {
    const { state } = useLocation();
    const role = sessionStorage.getItem("role")
    const { mainStore, crmStore } = useStores()
    const EXtractRole = role.split(",")
    const [newState, setNewState] = useState(state?.item)
    const [submitModal, setSubmitModal] = useState(false)
    const [addTRmodal, setAddTRModal] = useState(false)
    const navigate = useNavigate()
    const [auditTrail, setAuditTrail] = useState([])
    const [showAll, setShowAll] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [trNo, setTrNo] = useState("")
    const [description, setDescription] = useState(newState?.description)
    const [accRes, setAccRes] = useState(newState?.status_id);
    const [page, setPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(10)
    const [comment, setComment] = useState("")
    const [repComment, setRepComment] = useState("")
    const [reporter, setReporter] = useState(newState?.reporter?.name)
    const [summary, setSummary] = useState(newState?.issue_summary);
    const [issueTypeList, setIssueTypeList] = useState([]);
    const [selectedIssueType, setSelectedIssueType] = useState(newState?.issue_type?.id);
    const [entityList, setEntityList] = useState([]);
    const [selectedEntity, setSelectedEntity] = useState(newState?.entity_id);
    const [plantList, setPlantList] = useState([]);
    const [selectedPlant, setSelectedPlant] = useState(newState?.company_plants?.plant_id);
    const [categoryList, setCategoryList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(newState?.category?.category_id);
    const [subCategoryList, setSubCategoryList] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState(newState?.subcategory?.subcategory_id);
    const [statusList, setStatusList] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [priorityList, setPriorityList] = useState([]);
    const [selectedPriority, setSelectedPriority] = useState(newState?.priority?.priority_id);
    const [isReadOnly, setIsReadOnly] = useState(false)
    const [attachments, setAttachments] = useState([])
    const [showImageModal, setShowImageModal] = useState(false)
    const [imageModalTitle, setImageModalTitle] = useState("")
    const [viewImageBase64, setViewImageBase64] = useState('')
    const [viewImagefileType, setViewImageFileType] = useState('')



    const [trDetails, setTrDetails] = useState([{ tr_number: "", description: "" }]);



    const handleTRChange = (index, value) => {
        const updatedTrDetails = [...trDetails];
        updatedTrDetails[index].tr_number = value;
        setTrDetails(updatedTrDetails);
    };

    const handleDescChange = (index, value) => {
        const updatedTrDetails = [...trDetails];
        updatedTrDetails[index].description = value;
        setTrDetails(updatedTrDetails);
    };

    const handleAddInputGroup = () => {
        setTrDetails([...trDetails, { tr_number: "", description: "" }]);
    };

    const handleRemoveInputGroup = (index) => {
        const updatedTrDetails = [...trDetails];
        updatedTrDetails.splice(index, 1);
        setTrDetails(updatedTrDetails);
    };


    const openTRmodal = () => {
        setAddTRModal(true)
    }
    const handleSendTrDetails = async () => {
        let payload = {
            "tr_numbers": trDetails
        }
        setIsLoading(true)
        await crmStore.addTdDetails(newState?.complaint_id, payload, (res) => {
            if (res?.data?.meta?.status == "success") {
                notify(res?.data?.meta?.message)
                setIsLoading(false)
                setAddTRModal(false)
                setTrNo("")
                fetchAuditTrail()
                setDescription("")
                setTrDetails([])
                CallFetchedComplain()
            }
        }, (err) => {
            notify(err?.response?.data?.meta?.message, "danger")
            setIsLoading(false)
        })

    }

    useEffect(() => {
        CallFetchedComplain()
    }, [])

    useEffect(() => {
        fetchAllCategories();
        getAllEntity();
        getPriorities();
        getIssuesTypes();
        getPlantByCompanyId(selectedEntity)
        fetchSubCategoryById(selectedCategory)
    }, [])

    const getPriorities = () => {
        crmStore.getPriorityNoFilters((status, res) => {
            if (res?.meta?.status === "success") {
                setPriorityList(res?.data?.getAllComplaintPriorityLevels)
            } else {
                console.log(res?.data?.meta?.message, "setDeparmentList")
            }
        })
    }


    const getIssuesTypes = async () => {
        await crmStore?.getIssueTypeNoFilters((status, res) => {
            if (res?.meta?.status === "success") {
                setIssueTypeList(res?.data?.getAllComplaintIssueType)
            }
        }, (err) => {
            notify(err?.response?.meta?.message, "danger")
        })
    };


    const fetchAllCategories = () => {
        setIsLoading(true)
        crmStore.getAllCategories(

            (status, res) => {
                console.log(res)
                if (res?.meta?.status === "success") {
                    setCategoryList(res.data.categories);
                    setIsLoading(false)
                } else {
                    setIsLoading(false)
                }
            },
            (error) => {
                notify(error?.response?.data?.meta?.message, "danger")
                setIsLoading(false)
            })
    }

    const fetchSubCategoryById = (category_id) => {
        let payload = {
            multupleCategoryIds: [category_id]
        }
        crmStore.getAllSubcategories(
            payload,
            (res) => {
                if (res?.data?.meta?.status === "success") {
                    setSubCategoryList(res.data.data.filteredData);
                }
            },
            (error) => {
                notify(error?.response?.data?.meta?.message, "danger")
                setIsLoading(false)
            })
    }

    const getAllEntity = async () => {
        await mainStore.getEntity((status, res) => {
            if (res?.meta?.status === "success") {
                setEntityList(res?.data?.entitites)
            } else {
                console.log(res?.data?.meta?.message, "businessList")
            }
        })
    }

    const getPlantByCompanyId = async (id) => {
        let payload = {
            company_id: id
        }
        await crmStore.getAllCompanyPlant(payload, (res) => {
            if (res?.data?.meta?.status === "success") {
                setPlantList(res?.data?.data)
            } else {
                console.log(res?.data?.meta?.message, "businessList")
            }
        })
    }

    const CallFetchedComplain = async () => {
        setIsLoading(true)
        await crmStore.getCompById(newState?.complaint_id, (status, res) => {
            if (res?.meta?.status === "success") {
                setNewState(res?.data?.complaintDetail);
                setAccRes(res?.data?.complaintDetail?.status_id)
                setIsLoading(false)
            }

        }, (err) => {
            notify(err?.response?.data?.meta?.message, "danger")
            setIsLoading(false)
        })
    }



    const toggleVisibility = () => {
        setShowAll(prevState => !prevState);
    };
    const dataToDisplay = showAll ? auditTrail : [auditTrail[0]]


    useEffect(() => {
        fetchAuditTrail()
    }, [])

    const fetchAuditTrail = async () => {
        await crmStore.auditTrailCms(newState?.complaint_id, (status, res) => {
            if (res?.meta?.status == "success") {
                setAuditTrail(res?.data?.auditTrailRecords)
            } else {
                console.log(res?.meta?.message, "error");

            }
        }, (error) => {
            notify(error?.response?.meta?.message, "danger")
        })
    }

    // const Acknowledge = async () => {
    //     let payload = {
    //         complaint_id: newState?.complaint_id
    //     }
    //     setIsLoading(true)
    //     await crmStore?.acknowledgeTicket(payload, (res) => {
    //         if (res?.data?.meta?.status == "success") {
    //             notify(res?.data?.meta?.message)
    //             setIsLoading(false)
    //             CallFetchedComplain()
    //         }
    //     }, (err) => {
    //         notify(err?.response?.data?.meta?.message, "danger")
    //         setIsLoading(false)
    //     })
    // }

    const AddComment = async (type) => {
        let payload = {
            comment: type == "public" ? repComment : comment,
            type: type
        }
        if (attachments.length > 0) {
            payload.attachments = attachments
        }
        setIsLoading(true)
        await crmStore.addComment(newState?.complaint_id, payload, (res) => {
            if (res?.data?.meta?.status == "success") {
                notify(res?.data?.meta?.message)
                CallFetchedComplain()
                fetchAuditTrail()
                setIsLoading(false)
                setComment("")
                setRepComment("")
                setAttachments([])
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
            setIsLoading(false)
        })
    }

    const getFormattedDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames[date.getMonth()];
        const day = date.getDate();

        return `${month} ${day}, ${year}`;
    };

    const getClassName = (status) => {
        switch (status) {
            case 'Open':
                return 'circle-open ';
            case 'Closed':
                return 'circle-closed';
            case 'UAT Pending':
                return 'circle-Pending';
            case 'In-Progress':
                return 'circle-progress';
            case 'Cancelled':
                return 'circle-cancelled';
            case 'UAT Completed':
                return 'circle-Completed';
            default:
                return '';
        }
    };

    const handleEditorChange = (content) => {
        setDescription(content);
    };




    const handleSubmit = async () => {
        let payload = {
            issue_summary: summary,
            entity_id: selectedEntity,
            description: description,
            category_id: selectedCategory,
            subcategory_id: selectedSubCategory,
            priority_id: selectedPriority,
            issue_type_id: selectedIssueType,
            plant_id: selectedPlant
        }
        setIsLoading(true)

        await crmStore.editComplaint(newState?.complaint_id, payload, (res) => {
            if (res?.data?.meta?.status == "success") {
                notify(res?.data?.meta?.message)
                setSubmitModal(true)
                setIsLoading(false)
                navigate("/Dashboard")
            } else {
                notify(res?.data?.meta?.message, "danger")
                setIsLoading(false)
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
            setIsLoading(false)
        })
    }

    const handleUpload = async (e) => {
        debugger
        setIsLoading(true);
        const files = e.target.files;
        let formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append("file", files[i]);
            formData.append('filepath', 'cmtAttachment');
            await crmStore.uploadfile(formData, (response) => {
                if (response?.data?.meta?.status === "success") {
                    notify(response?.data?.meta?.message);
                    setAttachments((prevState) => [...prevState, response.data.data]);
                    setIsLoading(false);
                } else {
                    notify(response?.data?.meta?.message, "danger");
                    setIsLoading(false);
                }
            },
                (error) => {
                    notify(error?.response?.data?.meta?.message, "danger");
                    setIsLoading(false);
                }
            );
            formData.delete("file");
            formData.delete("filepath");
        }
    }

    const handledownload = async (filename) => {
        let payload = {
            filename: filename,
        };

        callDownloadApi(payload);
    };

    const callDownloadApi = async (payload) => {
        setIsLoading(true);
        await crmStore.downloadImage(
            payload,
            (response) => {
                if (response?.data?.meta?.status === "success") {
                    notify(response?.data?.meta?.message);
                    setIsLoading(false);
                    const base64File = response?.data?.data?.base64File;
                    const mimeType = response?.data?.data?.mimeType;
                    const fileNameParts = response?.data?.data?.filename?.split("-");
                    const fileName = fileNameParts.slice(1).join("-");
                    if (base64File && mimeType) {
                        const downloadLink = document.createElement("a");
                        downloadLink.href = `data:${mimeType};base64,${base64File}`;
                        downloadLink.download = fileName;
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        document.body.removeChild(downloadLink);
                    } else {
                        notify("Download link or File type not found", "danger");
                    }
                } else {
                    notify(response?.data?.meta?.message, "danger");
                    setIsLoading(false);
                }
            },
            (error) => {
                notify(error?.response?.data?.meta?.message, "danger");
                setIsLoading(false);
            }
        );
    }

    const handleView = async (filename) => {
        let payload = {
            filename: filename,
        };
        setIsLoading(true);
        await crmStore.downloadImage(payload, (response) => {
            if (response?.data?.meta?.status === "success") {
                setViewImageBase64(response?.data?.data?.base64File)
                setImageModalTitle(filename?.split("-")[1])
                setViewImageFileType(response?.data?.data?.mimeType)
                setShowImageModal(true)
                setIsLoading(false);
            }
        },
            (error) => {
                notify(error?.response?.data?.meta?.message, "danger");
                setIsLoading(false);
            });
    }

    const handleRemoveFile = async (filename, index) => {
        let payload = {
            filename: filename,
        };
        setAttachments(prevAttachments => prevAttachments.filter((_, i) => i !== index));
        callRemoveFileApi(payload);
    };

    const callRemoveFileApi = async (payload) => {
        setIsLoading(true);
        await crmStore.removedImage(payload, (response) => {
            if (response?.data?.meta?.status === "success") {
                notify(response?.data?.meta?.message);
                setIsLoading(false);
            } else {
                notify(response?.data?.meta?.message, "danger");
                setIsLoading(false);
            }
        },
            (error) => {
                notify(error?.response?.data?.meta?.message, "danger");
                setIsLoading(false);
            }
        );
    }

    return (
        <div className="main-container raiseTicket_container_crm">
            {isLoading && <Loader />}
            <div className="right-view">
                <Header title={'Edit My Ticket,'} />
                <div className="container-fluid px-0">
                    <div className="row">
                        <div className="col-md-12">
                            <CustomCard
                                cardtitle={"Ticket"}
                                cardboldtitle={"Details"}
                                cardbody={<>
                                    <img src={require("../../../../assets/images/left-arrow.png")} className="back-btn-dashboard" onClick={() => navigate(-1)} />
                                    <div className="ticket_detaile_box">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <Textarea
                                                    label={'Summary'}
                                                    require
                                                    placeholder={'Please write a comment.....'}
                                                    title={summary}
                                                    onChange={(e) => { setSummary(e.target.value) }}
                                                />
                                            </div>
                                            <div className="col-md-12 textarea" style={{ backgroundColor: "'#f0efef'" }}>
                                                <TextEditor
                                                    label={'Description'}
                                                    require
                                                    placeholder={'Please write a comment.....'}
                                                    value={description}
                                                    onChange={handleEditorChange}
                                                    style={{ backgroundColor: "'#f0efef'" }}
                                                />
                                            </div>


                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <CustomInput
                                                    label={'Reporter'}
                                                    require
                                                    placeholder={'Reporter'}
                                                    // value={candidateData.name}
                                                    value={reporter}
                                                    onChange={(e) => setReporter(e.target.value)}
                                                    readOnly
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <Customselect
                                                    label={'Ticket Type'}
                                                    require
                                                    value={selectedIssueType}
                                                    onChange={(e) => { setSelectedIssueType(e.target.value) }}
                                                    optionview={
                                                        issueTypeList?.length > 0 &&
                                                        issueTypeList?.map((item) => {
                                                            return (
                                                                <option value={item?.id}>{item?.name}</option>
                                                            )
                                                        })
                                                    }
                                                />
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <Customselect
                                                    label={'Entity'}
                                                    require
                                                    readOnly
                                                    placeholder={'Entity'}
                                                    value={selectedEntity}
                                                    onChange={(e) => {
                                                        setSelectedEntity(e.target.value)
                                                        getPlantByCompanyId(e.target.value)
                                                    }}
                                                    optionview={
                                                        entityList?.length > 0 &&
                                                        entityList?.map((item) => {
                                                            return (
                                                                <option value={item?.entity_code}>{item?.name}</option>
                                                            )
                                                        })
                                                    }
                                                />
                                            </div>

                                            <div className="col-md-3">
                                                <Customselect
                                                    label={'Plant'}
                                                    require
                                                    readOnly
                                                    placeholder={'Plant'}
                                                    value={selectedPlant}
                                                    onChange={(e) => {
                                                        setSelectedPlant(e.target.value)
                                                    }}
                                                    optionview={
                                                        plantList?.length > 0 &&
                                                        plantList?.map((item) => {
                                                            return (
                                                                <option value={item?.plant_id}>{item?.plant_name}</option>
                                                            )
                                                        })
                                                    }
                                                />
                                            </div>


                                            <div className="col-md-3">
                                                <Customselect
                                                    label={'Select Category'}
                                                    require
                                                    value={selectedCategory}
                                                    onChange={(e) => {
                                                        setSelectedCategory(e.target.value)
                                                        fetchSubCategoryById(e.target.value)
                                                    }}
                                                    optionview={
                                                        categoryList?.length > 0 &&
                                                        categoryList?.map((item) => {
                                                            return (
                                                                <option value={item?.category_id}>{item?.category_name}</option>
                                                            )
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <Customselect
                                                    label={'Select Sub-Category'}
                                                    require
                                                    value={selectedSubCategory}
                                                    onChange={(e) => {
                                                        setSelectedSubCategory(e.target.value)
                                                    }}
                                                    optionview={
                                                        subCategoryList?.length > 0 &&
                                                        subCategoryList?.map((item) => {
                                                            return (
                                                                <option value={item?.subcategory_id}>{item?.subcategory_name}</option>
                                                            )
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <Customselect
                                                    label={'Select Priority'}
                                                    require
                                                    value={selectedPriority}
                                                    onChange={(e) => { setSelectedPriority(e.target.value) }}
                                                    optionview={
                                                        priorityList?.length > 0 &&
                                                        priorityList?.map((item) => {
                                                            return (
                                                                <option value={item?.priority_level}>{item?.priority_name}</option>
                                                            )
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                                }
                            />

                            <CustomCard
                                cardboldtitle={"TR Number"}
                                toprightview={
                                    <CustomButton onClick={openTRmodal} children={'+ Add TR'} />
                                }

                                cardbody={
                                    <div className="ticket_detaile_box">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="tr_table_box">
                                                    <thead className="trNumber">
                                                        <tr>
                                                            <th>S.no.</th>
                                                            <th>Number</th>
                                                            <th>Description</th>
                                                        </tr>
                                                    </thead>
                                                    {newState?.tr_numbers?.length > 0 ?
                                                        (
                                                            newState?.tr_numbers?.map((item, i) => {
                                                                return (
                                                                    <tbody className="trNumber">
                                                                        <tr key={item?.tr_number}>
                                                                            <td>{i + 1}</td>
                                                                            <td>{item?.tr_number}</td>
                                                                            <td>{item?.description}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                )
                                                            })
                                                        )
                                                        : (
                                                            <tbody>
                                                                <tr>
                                                                    <td className="text-center p-3" colSpan={3}>
                                                                        No Records found...
                                                                    </td>
                                                                </tr>
                                                            </tbody>

                                                        )}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                }
                            />

                            <CustomCard
                                cardtitle={"Comment"}
                                cardboldtitle={"Details"}

                                cardbody={
                                    <div className="ticket_detaile_box">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <img className="commentImg1" src={commentImg1} alt="comment image" />
                                                {!EXtractRole.includes("Employee") && <img className="commentImg" src={commentImg} alt="comment image" />}
                                                <Tabs
                                                    defaultActiveKey="home"
                                                    id="uncontrolled-tab-example"
                                                    className="mb-3"
                                                >

                                                    {!EXtractRole.includes("Employee") && <Tab eventKey="profile" title="Internal Comments">
                                                        <div className="row comment_input_box login-text attachments-box">
                                                            <CustomInput
                                                                label="Add internal comment"
                                                                placeholder={'Write to add internal comment...'}
                                                                value={comment}
                                                                onChange={(e) => setComment(e.target.value)}

                                                            />
                                                            <div className="attachment-container">
                                                                <div title="Attach file" className="attachment-input">
                                                                    <label htmlFor="fileUpload" className="attachment-label">
                                                                        <img src={require("../../../../assets/images/attached_file.png")} alt="Attach" className="attachment-icon" />
                                                                    </label>
                                                                    <input
                                                                        id="fileUpload"
                                                                        type="file"
                                                                        onChange={handleUpload}
                                                                        disabled={isReadOnly}
                                                                    />
                                                                </div>

                                                                <div title="Click to add comment" className="send-attachments">
                                                                    <CustomButton
                                                                        children="Send"
                                                                        onClick={() => AddComment("internal")}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="uploaded-images attachment-grid">
                                                                {attachments.map((order, index) => (
                                                                    <div style={{ margin: '0' }} className="d-flex vieww-box">
                                                                        <div key={index} className="url-download" style={{ cursor: "pointer", color: "#65a6fa", paddingBottom: '0px' }}
                                                                        >{order?.split("-")[1]} </div>
                                                                        <div className="circle-box btn-container">
                                                                            <img title="View attachment" className="attachment-input" src={require("../../../../assets/images/vieww.png")} onClick={() => handleView(order)} />
                                                                            <img title="Download attachment" className="attachment-input" src={require("../../../../assets/images/downn.png")} onClick={() => handledownload(order)} />
                                                                            <img title="Remove attachment" className="attachment-input" src={require("../../../../assets/images/x.png")} onClick={() => handleRemoveFile(order, index)} />
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        <h3 className="comment_title">Comment History</h3>
                                                        <div className="comment_main_box">
                                                            {newState?.comments?.filter((item) => item.is_internal)?.map((item, index) => (
                                                                <div className="comment_box" key={index}>
                                                                    <h3 className="comment_title">
                                                                        {item.senderName}
                                                                        <span className="comment_time">
                                                                            {getFormattedDate(item.created_at)}
                                                                        </span>
                                                                    </h3>
                                                                    <p className="comment_text">{item.content}</p>
                                                                    <div className="uploaded-images attachment-grid">
                                                                        {item?.attachments?.length > 0 && item?.attachments.map((attachment, idx) => (
                                                                            <div style={{ margin: '0' }} className="d-flex vieww-box">
                                                                                <div key={idx} className="url-download" style={{ cursor: "pointer", color: "#65a6fa", paddingBottom: '0px' }}
                                                                                >{attachment?.split("-")[1]} </div>
                                                                                <div className="d-flex">
                                                                                    <img title="View attachment" className="attachment-input" src={require("../../../../assets/images/vieww.png")} onClick={() => handleView(attachment)} />
                                                                                    <img title="Download attachment" className="attachment-input" src={require("../../../../assets/images/downn.png")} onClick={() => handledownload(attachment)} />
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </Tab>}

                                                    <Tab eventKey="home" title="Public Comments">
                                                        <div className="row comment_input_box login-text attachments-box">
                                                            <CustomInput
                                                                label="Add public comment"
                                                                placeholder={'Write to add public comment...'}
                                                                value={repComment}
                                                                onChange={(e) => setRepComment(e.target.value)}
                                                            />
                                                            <div className="attachment-container">
                                                                <div title="Attach file" className="attachment-input">
                                                                    <label htmlFor="fileUpload" className="attachment-label">
                                                                        <img src={require("../../../../assets/images/attached_file.png")} alt="Attach" className="attachment-icon" />
                                                                    </label>
                                                                    <input
                                                                        id="fileUpload"
                                                                        type="file"
                                                                        onChange={handleUpload}
                                                                        disabled={isReadOnly}
                                                                    />
                                                                </div>

                                                                <div title="Click to add comment" className="send-attachments">
                                                                    <CustomButton
                                                                        children="Send"
                                                                        onClick={() => AddComment("public")}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="uploaded-images attachment-grid">
                                                                {attachments.map((order, index) => (
                                                                    <div style={{ margin: '0' }} className="d-flex vieww-box">
                                                                        <div key={index} className="url-download" style={{ cursor: "pointer", color: "#65a6fa", paddingBottom: '0px' }}
                                                                        >{order?.split("-")[1]} </div>
                                                                        <div className="circle-box btn-container">
                                                                            <img title="View attachment" className="attachment-input" src={require("../../../../assets/images/vieww.png")} onClick={() => handleView(order)} />
                                                                            <img title="Download attachment" className="attachment-input" src={require("../../../../assets/images/downn.png")} onClick={() => handledownload(order)} />
                                                                            <img title="Remove attachment" className="attachment-input" src={require("../../../../assets/images/x.png")} onClick={() => handleRemoveFile(order, index)} />
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        <h3 className="comment_title">Comment History</h3>
                                                        <div className="comment_main_box">
                                                            {newState?.comments?.filter((item) => !item.is_internal)?.map((item, index) => (
                                                                <div className="comment_box" key={index}>
                                                                    <h3 className="comment_title">
                                                                        {item.senderName}
                                                                        <span className="comment_time">
                                                                            {getFormattedDate(item.created_at)}
                                                                        </span>
                                                                    </h3>
                                                                    <p className="comment_text">{item.content}</p>
                                                                    <div className="uploaded-images attachment-grid">
                                                                        {item?.attachments?.length > 0 && item?.attachments.map((attachment, idx) => (
                                                                            <div style={{ margin: '0' }} className="d-flex vieww-box">
                                                                                <div key={idx} className="url-download" style={{ cursor: "pointer", color: "#65a6fa", paddingBottom: "0px" }}
                                                                                >{attachment?.split("-")[1]} </div>
                                                                                <div className="d-flex">
                                                                                    <img className="attachment-input" src={require("../../../../assets/images/vieww.png")} onClick={() => handleView(attachment)} />
                                                                                    <img className="attachment-input" src={require("../../../../assets/images/downn.png")} onClick={() => handledownload(attachment)} />
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </Tab>


                                                </Tabs>
                                            </div>
                                        </div>
                                    </div>
                                }
                            />

                            <CustomCard
                                cardtitle={"Audit"}
                                cardboldtitle={" trail"}
                                toprightview={
                                    <>
                                        <CustomButton style={"me-3"} children={'Show All Pipeline'} onClick={toggleVisibility} />
                                        <button className="export-btn">
                                            <ExcelFile element={<div> <img width={20} className="me-2" src={require("../../../../assets/images/excel.png")} />Export</div>}>
                                                <ExcelSheet data={auditTrail?.map((item, i) => ({
                                                    ...item, index: i + 1, role: item?.role,
                                                    created_at: item?.created_at && `${item.created_at?.split("T")[0]} ${item.created_at?.split("T")[1]?.split(".")[0].substring(0, 5)}`,
                                                }))} name="Sheet">
                                                    <ExcelColumn label="Status Date & Time" value="created_at" />
                                                    <ExcelColumn label="Reporter" value="reporter_name" />
                                                    <ExcelColumn label="Assignee" value="assignee" />
                                                    <ExcelColumn label="Support Level" value="support_level" />
                                                    <ExcelColumn label="Status" value="status" />
                                                    <ExcelColumn label="Comment" value="comments" />
                                                </ExcelSheet>
                                            </ExcelFile>
                                        </button>
                                    </>
                                }
                                cardbody={
                                    <React.Fragment>
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead class="table-customlight">
                                                    <tr>
                                                        <th className="auditTrailcms">Status Date & Time</th>
                                                        <th className="auditTrailcms">Reporter</th>
                                                        <th className="auditTrailcms">Assignee</th>
                                                        <th className="auditTrailcms">Support Level</th>
                                                        <th className="auditTrailcms">Status</th>
                                                        <th className="auditTrailcms">Comment</th>
                                                        <th className="auditTrailcms">Remarks</th>
                                                    </tr>
                                                </thead>
                                                {dataToDisplay?.length > 0 && (
                                                    <tbody>
                                                        {dataToDisplay?.map((item, i) => {
                                                            return (
                                                                <tr key={item?.requestNumber}>
                                                                    <td scope="row"><FormatDate dateString={item?.created_at} /> </td>
                                                                    <td>{item?.reporter_name}</td>
                                                                    <td>{item?.assignee}</td>
                                                                    <td>{item?.support_level}</td>
                                                                    <td>
                                                                        <div className="circle-box">
                                                                            <span className={getClassName(item?.status)}></span>{item?.status}
                                                                        </div>
                                                                    </td>
                                                                    <td className="status-box">{item?.comments}</td>
                                                                    <td className="status-box">{item?.remarks}</td>
                                                                </tr>)
                                                        })}
                                                    </tbody>)}
                                            </table>
                                        </div>
                                    </React.Fragment>
                                }
                            />
                        </div>

                        <div className="col-md-12 text-end">
                            <div>
                                <CustomButton style={'btn_margin'} children={EXtractRole == "Employee" ? 'Send for L1 approval' : 'Send for L2 approval'} onClick={handleSubmit} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <SubmitTicketModal
                show={submitModal}
                hide={() => setSubmitModal(false)}
                title={`Your ticket id: #${newState?.complaint_id} has been sent for approval`}
            />
            <AddTRNumberModal
                show={addTRmodal}
                hide={() => setAddTRModal(false)}
                trDetails={trDetails}
                handleTR={handleTRChange}
                handleDesc={handleDescChange}
                handleAddInputGroup={handleAddInputGroup}
                handleRemoveInputGroup={handleRemoveInputGroup}
                handleSendTrDetails={handleSendTrDetails}
            />

            <ImageModal
                show={showImageModal}
                onHide={() => { setShowImageModal(false) }}
                title={imageModalTitle}
                base64={viewImageBase64}
                fileType={viewImagefileType}
            />
        </div >
    );
};

export default EditTickets;
