import React, { useState, useEffect, useRef } from "react";
import CustomCard from "../CustomeCard";
import CustomButton from "../Button/Button";
import "./style.scss";
import { useNavigate, useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Customselect from "../CustomSelect";
import { Link } from "react-router-dom";
import ReactExport from "react-export-excel";
import CustomeInputForm from "../customInputForm";
import MultiColoredProgressBar from "./MultiColoredProgressBar";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ComplaintDashboard = ({
    handleView,
    allComplainData,
    complaintData,
    handlePageSubmit,
    page,
    pageLimit,
    limit,
    handleEdit,
    limitChangeHandler,
    handleFilter,
    handleReset,
    categoryList,
    handleSelectCategory,
    selectCategory,
    priorityList,
    handlePriority,
    selectedPriority,
    targetRowRef,
    handleToDate,
    handleFromDate,
    toDate,
    fromDate,
    complaintStatusList,
    handleSelectStatus,
    selectedComplaintStatus
}) => {
    const location = useLocation();
    const url = location?.pathname?.split("/")[1];
    const navigate = useNavigate();
    const role = sessionStorage.getItem("role")
    const EXtractRole = role.split(",")
    const [selectedCandidateId, setSelectedCandidateId] = useState(sessionStorage.getItem('selectedCandidateId') || null);
    let sessions = sessionStorage.getItem("candidate")
    let session = JSON.parse(sessions)
    let crmApprover = ["L2", "L3"]

    useEffect(() => {
        if (selectedCandidateId) {
            sessionStorage.setItem('selectedCandidateId', selectedCandidateId);
        }
    }, [selectedCandidateId]);

    const handleEditClick = (candidate) => {
        setSelectedCandidateId(candidate.complaint_id);
        handleEditForm(candidate);
        sessionStorage.setItem('selectedCandidateId', candidate.complaint_id);
    };

    const handleViewClick = (candidate) => {
        setSelectedCandidateId(candidate.complaint_id);
        handleViewForm(candidate);
        sessionStorage.setItem('selectedCandidateId', candidate.complaint_id);
    };

    const getClassName = (status) => {
        switch (status) {
            case 'Open':
                return 'circle-open ';
            case 'Closed':
                return 'circle-closed';
            case 'UAT Pending':
                return 'circle-Pending';
            case 'In-Progress':
                return 'circle-progress';
            case 'Cancelled':
                return 'circle-cancelled';
            case 'UAT Completed':
                return 'circle-Completed';
            default:
                return '';
        }
    };

    const handleViewForm = (e) => handleView(e);
    const handleEditForm = (e) => handleEdit(e);

    const getFormattedDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        const storedPage = sessionStorage.getItem('currentPage');
        const storedPageLimit = sessionStorage.getItem('pageLimit');

        if (storedPage) {
            handlePageSubmit({ selected: parseInt(storedPage) - 1 });
        }

        if (storedPageLimit) {
            limitChangeHandler(storedPageLimit);
        }
    }, []);

    const scrollToRow = () => {
        debugger
        const element = document.getElementById(selectedCandidateId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            element.focus();
        }
    };

    useEffect(() => {
        setTimeout(() => {
            scrollToRow();
        }, 1800);

    }, [selectedCandidateId]);

    // if (complaintData.length > 0) {
    //     scrollToRow()
    // }

    const [currentPage, setCurrentPage] = useState(() => {
        return parseInt(sessionStorage.getItem('currentPage'), 10) - 1 || 0;
    });

    useEffect(() => {
        sessionStorage.setItem('currentPage', currentPage + 1);
    }, [currentPage]);

    const handlePageChange = (selectedItem) => {
        const selectedPage = selectedItem.selected;
        setCurrentPage(selectedPage);
        handlePageSubmit({ selected: selectedPage });
    };


    const handleResett = () => {
        setCurrentPage(0)
        handleReset()
    }

    const getFormattedDatetable = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames[date.getMonth()];
        const day = date.getDate();

        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        const formattedTime = `${hours}:${minutes} ${ampm}`;


        return ` ${day} ${month}, ${year} , ${formattedTime}`;
    };

    return (
        <div className="manpower-requisition-container">
            <CustomCard
                cardtitle={"All "}
                cardboldtitle={"Tickets"}
                toprightview={
                    <div className="mt-4">
                        {window?.location?.pathname !== "/raise-ticket" && window?.location?.pathname !== "/my-ticket" &&
                            <CustomButton style={"me-3"} children={"View All Tickets"} onClick={() => navigate("/raise-ticket")} />
                        }
                        {window?.location?.pathname !== "/Dashboard" && window?.location?.pathname !== "/my-ticket" && !role?.includes("Outsourced consultant/Vendor") && !(session?.support_levels?.some(element => crmApprover.includes(element)))
                            &&
                            <Link to="/add-ticket" style={{ textDecoration: "none" }} className="me-2">
                                <CustomButton children={'+ Add Ticket'} />
                            </Link>
                        }
                        <button className="export-btn">
                            <ExcelFile
                                element={
                                    <div>
                                        <img
                                            width={20}
                                            className="me-2"
                                            src={require("../../assets/images/excel.png")}
                                        />
                                        Export
                                    </div>
                                }
                            >
                                <ExcelSheet
                                    data={complaintData?.map((item, i) => ({
                                        ...item, index: i + 1,
                                        date: item?.created_at && getFormattedDate(item?.created_at),
                                        business: item?.business?.business_name,
                                        company: item?.company?.name,
                                        category: item?.category?.category_name,
                                        Status: item?.status?.complaint_status_name,
                                        priority: item?.priority?.priority_name,
                                        Assignee: item?.assignee?.name
                                    }))}
                                    name="Sheet"
                                >
                                    <ExcelColumn label="Candidate ID." value="complaint_id" />
                                    <ExcelColumn label="Creation Date" value="date" />
                                    <ExcelColumn label="Summary" value="issue_summary" />
                                    <ExcelColumn label="Category" value="category" />
                                    <ExcelColumn label="Status" value="Status" />
                                    <ExcelColumn label="Priority" value="priority" />
                                    <ExcelColumn label="Assignee" value="Assignee" />
                                </ExcelSheet>
                            </ExcelFile>
                        </button>
                    </div>
                }
                cardbody={
                    <React.Fragment>
                        <div className="row">
                            {window.location.pathname !== "/Dashboard" && <div className="col-md-12 filter-container">
                                <div className="">
                                    <Customselect
                                        label="Select Category"
                                        value={selectCategory}
                                        onChange={(e) => {
                                            handleSelectCategory(e.target.value);
                                        }}
                                        optionview={categoryList?.map((option) => {
                                            return (
                                                <option key={option?.category_id} value={option?.category_id}>{option?.category_name}</option>
                                            );
                                        })}
                                    />
                                </div>
                                <div>
                                    <Customselect
                                        label="Select Priority"
                                        value={selectedPriority}
                                        onChange={(e) => {
                                            handlePriority(e.target.value);
                                        }}
                                        optionview={priorityList?.map((option) => {
                                            return (
                                                <option key={option?.priority_id} value={option?.priority_id}>{option?.priority_name}</option>
                                            );
                                        })}
                                    />
                                </div>
                                <div className="">
                                    <CustomeInputForm
                                        // mandatory
                                        type={'date'}
                                        label={'From Date - To Date'}
                                        fromplaceholder={'From'}
                                        fromvalue={fromDate}
                                        fromonChange={(e) => handleFromDate(e.target.value)}
                                        toplaceholder={'To'}
                                        tovalue={toDate}
                                        toonChange={(e) => handleToDate(e.target.value)}
                                    />
                                </div>
                                {window?.location?.pathname !== "/view-complaint-status" && <div>
                                    <Customselect
                                        label="Select Status"
                                        value={selectedComplaintStatus}
                                        onChange={(e) => {
                                            handleSelectStatus(e.target.value);
                                        }}
                                        optionview={complaintStatusList?.map((option) => {
                                            return (
                                                <option key={option?.complaint_status_id} value={option?.complaint_status_id}>{option?.complaint_status_name}</option>
                                            );
                                        })}
                                    />
                                </div>}
                                <div className="btn-container" style={{ marginTop: "33px", marginLeft: "20px" }}>
                                    <CustomButton style={"me-3"} children={'Search'} onClick={handleFilter} />
                                    <CustomButton style={"me-3"} children={'Reset'} onClick={handleResett} />
                                </div>
                            </div>}

                            {window.location.pathname !== "/Dashboard" && <div className="col-md-12 mb-4">
                                <div>
                                    <label className="d-flex align-items-center p-0 col-md-2">
                                        {page === 1 ? (
                                            <select
                                                value={pageLimit}
                                                className="form-control selectbox-container mr-2 drop-down-dashboard"
                                                onChange={(e) => limitChangeHandler(e.target.value)}
                                            >
                                                {limit?.length > 0
                                                    ? limit?.map((item, index) => (
                                                        <option key={index} value={item.option}>
                                                            {item.value}
                                                        </option>
                                                    ))
                                                    : null}
                                            </select>
                                        ) : (
                                            <p className="form-control selectbox-container mr-2 drop-down-dashboard">
                                                {pageLimit}
                                            </p>
                                        )}
                                        <span> entries</span>
                                    </label>
                                </div>
                            </div>}

                            <div className="table-responsive">
                                <table className="table">
                                    <thead className="table-customlight">
                                        <tr>
                                            <th scope="col" className="manpower-table1">S.No</th>
                                            <th scope="col" className="manpower-table1">Complaint No</th>
                                            <th scope="col" className="manpower-Summary">Summary</th>
                                            <th scope="col" className="manpower-table">Category</th>
                                            {url !== "hr-portal-approver" && <th scope="col" className="manpower-table">Status</th>}
                                            <th scope="col" className="manpower-table">Priority</th>
                                            <th scope="col" className="manpower-table">Assignee</th>
                                            <th scope="col" className="manpower-table">Creation Date</th>
                                            {/* <th scope="col" className="manpower-table">TAT Progress</th> */}
                                            <th scope="col" className="manpower-table1">Action</th>
                                        </tr>
                                    </thead>
                                    {complaintData?.length > 0 ? (
                                        <tbody>
                                            {complaintData?.map((candidate, index) => {
                                                const isSelected = candidate.complaint_id == selectedCandidateId;
                                                return (
                                                    <tr
                                                        ref={targetRowRef}
                                                        key={candidate.complaint_id}
                                                        className={isSelected ? 'highlighted-row' : ''}
                                                        id={candidate.complaint_id}
                                                    >
                                                        <td>{(currentPage) * pageLimit + index + 1}</td>
                                                        <td scope="row">{candidate?.complaint_number}</td>
                                                        <td>{candidate?.issue_summary}</td>
                                                        <td>{candidate?.category?.category_name}</td>
                                                        <td>
                                                            <div className="circle-box">
                                                                <span className={getClassName(candidate?.status?.complaint_status_name)}></span> {candidate?.status?.complaint_status_name}
                                                            </div>
                                                        </td>
                                                        <td>{candidate?.priority?.priority_name}</td>
                                                        <td>{candidate?.assignee?.name}</td>
                                                        <td>{getFormattedDatetable(candidate?.created_at)}</td>
                                                        {/* <td>{<MultiColoredProgressBar complaintId={candidate?.complaint_id} />}</td> */}
                                                        <td>
                                                            <div className="td_action_box">
                                                                {(EXtractRole.includes("Employee") || (window.location.pathname == "/my-ticket" && session?.id == candidate?.reporter_id)) &&
                                                                    (candidate?.status?.complaint_status_name === "Open" && (
                                                                        <img
                                                                            src={require("../../assets/images/editCrm.png")}
                                                                            className="requisition-edit me-1"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                handleEditClick(candidate);
                                                                            }}
                                                                        />
                                                                    ))
                                                                }

                                                                <img
                                                                    src={require("../../assets/images/visible.png")}
                                                                    className="view-button-action"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        handleViewClick(candidate);
                                                                    }}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    ) : (
                                        <tbody>
                                            <tr>
                                                <td className="text-center p-3" colSpan={8}>
                                                    No Records found...
                                                </td>
                                            </tr>
                                        </tbody>
                                    )}
                                </table>
                            </div>
                            {window.location.pathname !== "/Dashboard" && <div className="col-md-12">
                                <div className="d-flex align-items-center justify-content-between my-4">
                                    <div>
                                        <p>
                                            showing {allComplainData?.filteredCount} out of{" "}
                                            {allComplainData?.totalRecords}
                                        </p>
                                    </div>
                                    <div>
                                        <ReactPaginate
                                            previousLabel={"Previous"}
                                            nextLabel={"Next"}
                                            breakLabel={"..."}
                                            pageCount={allComplainData?.totalPages}
                                            marginPagesDisplayed={3}
                                            pageRangeDisplayed={3}
                                            onPageChange={handlePageChange}
                                            containerClassName={"pagination paginationBox justify-content-end"}
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link pageNumber"}
                                            previousClassName={"page-item previosBtn"}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link previosBtn"}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link pageNumber"}
                                            activeClassName={"active"}
                                            forcePage={currentPage}
                                        />
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </React.Fragment>
                }
            />
        </div>
    );
};

export default ComplaintDashboard;
