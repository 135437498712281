import React, { useState, useEffect } from "react";
import CustomCard from "../../CustomeCard";
import CustomButton from "../../Button/Button";
import "./style.scss";
import { useNavigate, useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Customselect from "../../CustomSelect";
import ReactExport from "react-export-excel";



const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const HrRequisitionPortal = ({ handleView, handleAddNewRequisition, allCandidateList, candidateList, handlePageSubmit, page, pageLimit, limit,
    limitChangeHandler, handleName, handleEmail, handleInterviewer, handleContact, filterName, filterEmail, filterContact, filterInterviewer,
    handleFilter, handleReset, interviewList, targetRowRef
}) => {
    const [selectedCandidateId, setSelectedCandidateId] = useState(sessionStorage.getItem('hrId') || null);
    const location = useLocation();
    const url = location?.pathname?.split("/")[1];

    useEffect(() => {
        if (selectedCandidateId) {
            sessionStorage.setItem('hrId', selectedCandidateId);
        }
    }, [selectedCandidateId]);

    const scrollToRow = () => {
        const element = document.getElementById(selectedCandidateId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            element.focus();
        }
    };

    useEffect(() => {
        setTimeout(() => {
            scrollToRow();
        }, 1300);

    }, [selectedCandidateId]);

    if (allCandidateList.length > 0) {
        scrollToRow()
    }


   

    const getClassName = (status) => {
        switch (status) {
            case 'Pending':
                return 'circle-yellow';
            case 'Close':
                return 'circle-green';
            case 'Rejected':
                return 'circle-red';
            case 'Completed':
                return 'circle-green';
            case 'Hold':
                return 'circle-yellow';
            default:
                return '';
        }
    };

    const handleViewForm = (e) => {
        handleView(e)
        setSelectedCandidateId(e?.candidate_id)
        sessionStorage.setItem('hrId', e?.candidate_id);
    }

    const [currentPage, setCurrentPage] = useState(() => {
        return parseInt(sessionStorage.getItem('currentHrPage'), 10) - 1 || 0;
    });

    useEffect(() => {
        sessionStorage.setItem('currentHrPage', currentPage + 1);
    }, [currentPage]);

    const handlePageChange = (selectedItem) => {
        const selectedPage = selectedItem.selected;
        setCurrentPage(selectedPage);
        handlePageSubmit({ selected: selectedPage });
    };

    const handleResett = () => {
        setCurrentPage(0)
        handleReset()
    }

    const handleApplyFilter = async () => {
        await setCurrentPage(0)
        await handleFilter()
    }


    return (
        <div className="manpower-requisition-container">
            <CustomCard
                cardtitle={"All "}
                cardboldtitle={"Applications"}
                toprightview={
                    <div>
                        {url !== "hr-portal-approver" && <CustomButton style={"me-3"} children={"Add New Candidate"} onClick={handleAddNewRequisition} />}
                        <button className="export-btn">
                            <ExcelFile
                                element={
                                    <div>
                                        <img
                                            width={20}
                                            className="me-2"
                                            src={require("../../../assets/images/excel.png")}
                                        />
                                        Export
                                    </div>
                                }
                            >
                                <ExcelSheet
                                    data={allCandidateList?.map((item, i) => {
                                        const interviews = item?.candidateInterview;
                                        const lastInterview = interviews?.[interviews.length - 1];

                                        return {
                                            ...item,
                                            index: i + 1,
                                            Candidate: item?.candidate_id,
                                            name: item?.full_name,
                                            email_id: item?.email_id,
                                            Mobile: item?.contact,
                                            Interviewer: lastInterview?.interviewer_name,
                                            Status: item?.is_form_status,
                                            Application: item?.is_application_status,
                                        };
                                    })}

                                    name="Sheet"
                                >
                                    <ExcelColumn label="Candidate ID" value="Candidate" />
                                    <ExcelColumn label="Name" value="name" />
                                    <ExcelColumn label="Email ID" value="email_id" />
                                    <ExcelColumn label="Mobile no." value="Mobile" />
                                    <ExcelColumn label="Interviewer" value="Interviewer" />
                                    <ExcelColumn label="Form Status" value="Status" />
                                    <ExcelColumn label="Application status" value="Application" />
                                </ExcelSheet>
                            </ExcelFile>
                        </button>
                    </div>
                }
                cardbody={
                    <React.Fragment>
                        <div className="row">
                            <div className="col-md-12 filter-container">
                                {url !== "hr-portal-approver" && <div >
                                    <Customselect
                                        className="form-control"
                                        type="text"
                                        label="Select Interviewer"
                                        value={filterInterviewer}
                                        onChange={(e) => handleInterviewer(e.target.value)}
                                        optionview={interviewList?.map((option) => {
                                            return (
                                                <option value={option?.id}>{option?.name}</option>
                                            );
                                        })}
                                    />
                                </div>}

                                {url !== "hr-portal-approver" && <div className="btn-container" style={{ marginTop: "33px" }}>
                                    <CustomButton style={"me-3"} children={'Search'} onClick={handleApplyFilter} />
                                    <CustomButton style={"me-3"} children={'Reset'} onClick={handleResett} />
                                </div>}

                            </div>
                        </div>

                        <div className="col-md-12 mb-4">
                            <div>
                                <label className="d-flex align-items-center p-0 col-md-2">
                                    {page === 1 ? (
                                        <select
                                            value={pageLimit}
                                            className="form-control selectbox-container mr-2 drop-down-dashboard"
                                            onChange={(e) => limitChangeHandler(e.target.value)}
                                        >
                                            {limit.length > 0
                                                ? limit?.map((item, index) => (
                                                    <option key={index} value={item.option}>
                                                        {item.value}
                                                    </option>
                                                ))
                                                : null}
                                        </select>
                                    ) : (
                                        <p className="form-control selectbox-container mr-2 drop-down-dashboard">
                                            {pageLimit}
                                        </p>
                                    )}
                                    <span> Entries</span>
                                </label>
                            </div>
                        </div>


                        <div class="table-responsive">
                            <table class="table">
                                <thead class="table-customlight">
                                    <tr>
                                        <th scope="col" className="manpower-table1">Candidate ID</th>
                                        <th scope="col" className="manpower-table">Name</th>
                                        <th scope="col" className="manpower-table">Email ID</th>
                                        <th scope="col" className="manpower-table">Mobile no.</th>
                                        {url !== "hr-portal-approver" && <th scope="col">Interviewer</th>}
                                        <th scope="col" className="manpower-table">Form Status</th>
                                        <th scope="col" className="manpower-table">Application status</th>
                                        <th scope="col" className="manpower-table1">Action</th>
                                    </tr>
                                </thead>
                                {allCandidateList?.length > 0 ? (
                                    <tbody>
                                        {allCandidateList?.map(candidate => {
                                            const interviews = candidate?.candidateInterview
                                            const lastInterview = interviews?.[interviews.length - 1];
                                            const isSelected = candidate.candidate_id == selectedCandidateId;
                                            return (
                                                <tr
                                                    ref={targetRowRef}
                                                    key={candidate.candidate_id}
                                                    className={isSelected ? 'highlighted-row' : ''}
                                                    id={candidate?.candidate_id}
                                                >
                                                    <td scope="row">{candidate?.candidate_id}</td>
                                                    <td>{candidate?.full_name}</td>
                                                    <td>{candidate?.email_id}</td>
                                                    <td>{candidate?.contact}</td>
                                                    {url !== "hr-portal-approver" && <td>{lastInterview?.interviewer_name}</td>}
                                                    <td>
                                                        <div className="circle-box">
                                                            <span className={getClassName(candidate.is_form_status)}></span> {candidate.is_form_status}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="circle-box">
                                                            <span className={getClassName(candidate.is_application_status)}></span> {candidate.is_application_status}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <img src={require("../../../assets/images/visible.png")} className="view-button-action" onClick={() => handleViewForm(candidate)} />
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                ) : (
                                    <tbody>
                                        <tr>
                                            <td className="text-center p-3" colSpan={8}>
                                                No Records found...
                                            </td>
                                        </tr>
                                    </tbody>

                                )}
                            </table>
                        </div>
                        <div className="col-md-12">
                            <div className="d-flex align-items-center justify-content-between my-4">
                                <div>
                                    <p>
                                        Showing {pageLimit * currentPage + 1} - {Math.min(pageLimit * (currentPage + 1), candidateList?.totalRecords)}{" "}
                                        out of {candidateList?.totalRecords}
                                    </p>
                                </div>
                                <div>
                                    <ReactPaginate
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        breakLabel={"..."}
                                        pageCount={candidateList?.totalPages}
                                        marginPagesDisplayed={3}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePageChange}
                                        containerClassName={"pagination paginationBox justify-content-end"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link pageNumber"}
                                        previousClassName={"page-item previosBtn"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link previosBtn"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link pageNumber"}
                                        activeClassName={"active"}
                                        forcePage={currentPage}

                                    />
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }
            />
        </div>
    );
};

export default HrRequisitionPortal;
