import React, { useEffect, useState } from 'react'
import Header from '../../../component/Header'
import './style.scss'
import { useLocation, useNavigate } from "react-router-dom"
import CustomCard from '../../../component/CustomeCard';
import CustomInput from '../../../component/CustomInput';
import CustomButton from '../../../component/Button/Button';
import Customselect from '../../../component/CustomSelect';
import { useStores } from '../../../stores';
import notify from '../../../shared/notify';
import Loader from '../../../component/helper/Loader/loader';
import ReactExport from "react-export-excel";
import FormatDate from '../../../component/helper/formatDate/formateDate';



const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const ScheduleInterview = () => {
    const { state } = useLocation();
    const [newState, setNewState] = useState(state?.item)
    const navigate = useNavigate()
    const { crmStore } = useStores()
    const [isLoading, setIsLoading] = useState(false)
    const [selectApplicationDate] = useState(newState?.application_date?.split("T")[0])
    const [positionApplied] = useState(newState?.position_applied)
    const [departmentName] = useState(newState?.department)
    const [location] = useState(newState?.location)
    const [fullName] = useState(newState?.full_name)
    const [fatherName] = useState(newState?.father_name)
    const [EmailID] = useState(newState?.email_id)
    const [mobileNumber] = useState(newState?.contact)
    const [permanentAddress] = useState(newState?.permanent_address)
    const [presentAddress] = useState(newState?.present_address)
    const [pinCode] = useState(newState?.pin_code_permanent)
    const [pinCodePresent] = useState(newState?.pin_code_present)
    const [maritalStatus] = useState(newState?.maritial_status)
    const [numberOfBackLog] = useState(newState?.candidateBacklogs?.[0]?.number_of_backlogs || 0);
    const [totalExperience] = useState(newState?.total_experience)
    const [currentSalary] = useState(newState?.current_salary)
    const [noticePeriod] = useState(newState?.notice_period)
    const [expectedSalary] = useState(newState?.expected_salary)
    const [educationalDetails] = useState(newState?.candidateQualificationDetails ? newState?.candidateQualificationDetails : [])
    const [employmentDetails] = useState(newState?.candidateEmploymentDetails ? newState?.candidateEmploymentDetails : []);
    const [jobLocation, setJobLocation] = useState("")
    const [positionOffered, setPositionOffered] = useState("")
    const [compensationOffered, setCompensationOffered] = useState("")
    const [tentativeDateOfJoining, setTentativeDateOfJoining] = useState("")
    const [leadershipSkills, setLeadershipSkills] = useState("")
    const [remarks, setRemarks] = useState("")
    const interviews = newState?.candidateInterview;
    const lastInterview = interviews?.[interviews.length - 1];
    const [positionList, setPositionList] = useState([])

    const [academicGap, setAcademicGap] = useState(newState?.candidateBacklogs?.[0]?.academic_gap ? "Yes" : "No");
    const [dateOfBirth] = useState(newState?.date_of_birth?.split("T")[0] || '');
    const [expectedDateOfJoining] = useState(newState?.expected_doj?.split("T")[0] || '');
    const [auditTrail, setAuditTrail] = useState([])
    const [showAll, setShowAll] = useState(false);


    const toggleVisibility = () => {
        setShowAll(prevState => !prevState);
    };

    const dataToDisplay = showAll ? auditTrail : [auditTrail[0]]



    useEffect(() => {
        fetchPosition()
        fetchRequestById()
        fetchAuditTrail()
    }, [])

    const fetchAuditTrail = async () => {
        await crmStore.getAuditInterview(newState?.candidate_id, (status, res) => {
            if (res?.meta?.status == "success") {
                setAuditTrail(res?.data?.candidateAuditTrailRecords)
            } else {
                console.log(res?.meta?.message, "error");

            }
        }, (error) => {
            notify(error?.response?.meta?.message, "danger")
        })
    }

    const fetchPosition = async () => {
        await crmStore.getAllPosition((status, res) => {
            if (res?.meta?.status === "success") {
                setPositionList(res?.data?.getAllOrgPositions)
            } else {
                console.log(res?.data?.meta?.message, "setDeparmentList")
            }
        })
    }


    const fetchRequestById = async () => {
        await crmStore.getCnadDetailsId(newState?.candidate_id, (status, res) => {
            if (res?.meta?.status === "success") {
                setNewState(res?.data?.getCandidateData)
            } else {
                console.log(res?.meta?.message, "setDeparmentList")
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
        })
    }


    useEffect(() => {
        if (newState?.candidateBacklogs?.[0]?.academic_gap === true) {
            setAcademicGap("Yes");
        } else if (newState?.candidateBacklogs?.[0]?.academic_gap === false) {
            setAcademicGap("No");
        } else {
            setAcademicGap("");
        }
    }, [state]);


    const handleScheduleInterview = async () => {
        let id = newState?.candidate_id
        let payload = {
            job_location: jobLocation,
            position_offered: positionOffered,
            compensation_offered: compensationOffered,
            tentative_doj: tentativeDateOfJoining,
            leadership_skills: leadershipSkills,
            remarks: remarks,
        }

        setIsLoading(true)
        await crmStore.scheduleInterview(id, payload, (res) => {
            if (res?.data?.meta?.status === "success") {
                notify(res?.data?.meta?.message)
                setIsLoading(false)
                navigate("/hr-portal-dashboard")
            } else {
                notify(res?.data?.meta?.message, "danger")
                setIsLoading(false)
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
            setIsLoading(false)
        })
    }

    const getClassName = (status) => {
        switch (status) {
            case 'Pending':
                return 'circle-yellow';
            case 'Close':
                return 'circle-green';
            case 'Rejected':
                return 'circle-red';
            case 'Completed':
                return 'circle-green';
            case 'Hold':
                return 'circle-yellow';
            default:
                return '';
        }
    };

    function   getOrdinalSuffix(number) {
        const suffixes = ["th", "st", "nd", "rd"];
        const v = number % 100;
        return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
      }

    return (
        <div className="main-container manpower-requisition-container">
            {isLoading && <Loader />}
            <div className="right-view">
                <Header title={"Candidate Application Form,"} />
                <div className='row'>

                    <div className='col-md-12'>
                        <CustomCard
                            cardtitle={"Personal"}
                            cardboldtitle={"Details"}
                            cardbody={
                                <>
                                    <img src={require("../../../assets/images/left-arrow.png")} className="back-btn-dashboard" onClick={() => navigate(-1)} />
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Select Application Date"
                                                require={true}
                                                placeholder='Select Application Date'
                                                type='date'
                                                readOnly={true}
                                                value={selectApplicationDate}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Position Applied"
                                                require={true}
                                                placeholder='Position Applied'
                                                readOnly={true}
                                                value={positionApplied}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Department Name"
                                                require={true}
                                                placeholder='Department Name'
                                                readOnly={true}
                                                value={departmentName}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Location"
                                                require={true}
                                                placeholder='Location'
                                                readOnly={true}
                                                value={location}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Full Name"
                                                require={true}
                                                placeholder='Full Name'
                                                readOnly={true}
                                                value={fullName}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Father’s Name"
                                                require={true}
                                                placeholder='Father’s Name'
                                                readOnly={true}
                                                value={fatherName}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Mobile Number"
                                                require={true}
                                                placeholder='Mobile Number'
                                                readOnly={true}
                                                value={mobileNumber}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="E-mail ID"
                                                require={true}
                                                placeholder='E-mail ID'
                                                readOnly={true}
                                                value={EmailID}
                                            />
                                        </div>
                                        <div className='col-md-8'>
                                            <CustomInput
                                                label="Permanent Address"
                                                require={true}
                                                placeholder='Permanent Address'
                                                readOnly={true}
                                                value={permanentAddress}
                                            />
                                        </div>
                                        <div className='col-md-4'>
                                            <CustomInput
                                                label="Permanent Pincode"
                                                require={true}
                                                placeholder='Permanent Pincode'
                                                readOnly={true}
                                                value={pinCode}
                                            />
                                        </div>
                                        <div className='col-md-8'>
                                            <CustomInput
                                                label="Present Address"
                                                require={true}
                                                placeholder='Present Address'
                                                readOnly={true}
                                                value={presentAddress}
                                            />
                                        </div>
                                        <div className='col-md-4'>
                                            <CustomInput
                                                label="Present Pincode"
                                                require={true}
                                                placeholder='Present Pincode'
                                                readOnly={true}
                                                value={pinCodePresent}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="DOB"
                                                require={true}
                                                type="date"
                                                placeholder='DOB'
                                                readOnly={true}
                                                value={dateOfBirth}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Marital Status"
                                                require={true}
                                                placeholder='Marital Status'
                                                readOnly={true}
                                                value={maritalStatus}
                                            />
                                        </div>
                                    </div>

                                </>}
                        />
                    </div>

                    <div className='col-md-12'>
                        <CustomCard
                            cardtitle={"Educational"}
                            cardboldtitle={"Details"}
                            cardbody={
                                <div className='row'>
                                    {educationalDetails?.map((item, index) => {
                                        let data = item?.class !== "Post Graduation" ? true : false
                                        return (
                                            <>
                                                <div className='col-md-3'>
                                                    <CustomInput
                                                        label="Select Class Name"
                                                        require={data}
                                                        value={item?.class}
                                                        readOnly={true}
                                                        placeholder="Select Class Name"
                                                    />
                                                </div>
                                                <div className='col-md-3'>
                                                    <CustomInput
                                                        label="Name of Course"
                                                        require={data}
                                                        placeholder='Name of Course'
                                                        value={item?.course_name}
                                                        readOnly={true}
                                                    />
                                                </div>
                                                <div className='col-md-3'>
                                                    <CustomInput
                                                        label="Name of the Institute"
                                                        require={data}
                                                        placeholder='School/College/Institute Name'
                                                        value={item?.school_college_name}
                                                        readOnly={true}
                                                    />
                                                </div>
                                                <div className='col-md-3'>
                                                    <CustomInput
                                                        label="Board/University Name"
                                                        require={data}
                                                        placeholder='Board/University Name'
                                                        value={item?.board_university}
                                                        readOnly={true}
                                                    />
                                                </div>
                                                <div className='col-md-3'>
                                                    <CustomInput
                                                        label="Select Regular/Part-time"
                                                        require={data}
                                                        value={item?.education_type}
                                                        readOnly={true}
                                                        placeholder="Select Regular/Part-time"
                                                    />
                                                </div>
                                                <div className='col-md-3'>
                                                    <CustomInput
                                                        label="Select Year of Passing"
                                                        require={data}
                                                        placeholder='Select Year of Passing'
                                                        type="date"
                                                        value={item?.year_of_passing?.split("T")[0]}
                                                        readOnly={true}
                                                    />
                                                </div>
                                                <div className='col-md-3'>
                                                    <CustomInput
                                                        label="Percentage(%)/Grade"
                                                        require={data}
                                                        placeholder='Percentage(%)/Grade'
                                                        value={item?.percentage_grade}
                                                        readOnly={true}
                                                    />
                                                </div>
                                                <div className='col-md-3'>

                                                </div>
                                            </>
                                        )
                                    })}


                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='radio-type'>
                                                <h3>Academic Gap<span style={{ color: "red" }}>*</span></h3>
                                                <div className=' academic-box'>
                                                    <div className='radio-btnn' >
                                                        <input type='radio' name='Academic Gap' readOnly={true} value="Yes" checked={academicGap === "Yes"} />
                                                        <label >Yes</label>
                                                    </div>
                                                    <div className='radio-btnn'>
                                                        <input type='radio' name='Academic Gap' readOnly={true} value="No" checked={academicGap === "No"} />
                                                        <label >No</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Number of Backlogs, if any"
                                                require={true}
                                                placeholder='Number of Backlogs, if any'
                                                readOnly={true}
                                                value={numberOfBackLog}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                        />
                    </div>

                    {employmentDetails.map((detail, index) => (
                        <div key={index} className='col-md-12'>
                            <CustomCard
                                cardtitle={"Employment"}
                                cardboldtitle={`Details ${index + 1}`}
                                cardbody={
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Organization Name"
                                                require={true}
                                                readOnly={true}
                                                placeholder='Organization Name'
                                                value={detail.organization}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Designation Name"
                                                require={true}
                                                readOnly={true}
                                                placeholder='Designation Name'
                                                value={detail.designation}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Select From (Month, year)"
                                                require={true}
                                                readOnly={true}
                                                placeholder='Select From (Month, year)'
                                                type="date"
                                                value={detail.select_from_date?.split("T")[0]}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Select To (Month, year)"
                                                require={true}
                                                readOnly={true}
                                                placeholder='Select To (Month, year)'
                                                type="date"
                                                value={detail.select_to_date?.split("T")[0]}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Current CTC"
                                                require={true}
                                                readOnly={true}
                                                placeholder='Current CTC'
                                                value={detail.ctc}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Reason for Leaving"
                                                require={true}
                                                readOnly={true}
                                                placeholder='Reason for Leaving'
                                                value={detail.reason_for_leaving}
                                            />
                                        </div>

                                    </div>
                                }
                            />
                        </div>
                    ))}

                    <div className='col-md-12'>
                        <CustomCard
                            cardtitle={"Other"}
                            cardboldtitle={"Details"}
                            cardbody={
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Total Experience"
                                            require={true}
                                            placeholder='Total Experience'
                                            readOnly={true}
                                            value={totalExperience}

                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Current Salary"
                                            require={true}
                                            placeholder='Current Salary'
                                            readOnly={true}
                                            value={currentSalary}

                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Notice Period"
                                            require={true}
                                            placeholder='Notice Period'
                                            type="date"
                                            readOnly={true}
                                            value={noticePeriod}

                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Expected Salary"
                                            require={true}
                                            placeholder='Expected Salary'
                                            readOnly={true}
                                            value={expectedSalary}

                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Expected DOJ"
                                            require={true}
                                            type="date"
                                            placeholder='Expected DOJ'
                                            readOnly={true}
                                            value={expectedDateOfJoining}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>
                    <div className='col-md-12'>
                        {interviews?.map((item, index) => {
                            if (!item?.technical_knowledge) {
                                return null; // Skip rendering this card if the condition is not met
                            }
                            const roundNumber = index + 1; // Adjust if necessary
                            const dynamicTitle = ` from Interviewer ${item?.interviewer_name} for ${getOrdinalSuffix(roundNumber)} round`;

                            return ( 
                                <CustomCard
                                    key={index}
                                    cardtitle={"Feedback"}
                                    cardboldtitle={dynamicTitle}
                                    cardbody={
                                        <div className='row'>
                                            <div className='col-md-3'>
                                                <CustomInput
                                                    label="Job/Technical Knowledge"
                                                    placeholder="Job/Technical Knowledge"
                                                    require={true}
                                                    value={item?.technical_knowledge}
                                                    readOnly={true}
                                                />
                                            </div>
                                            <div className='col-md-3'>
                                                <CustomInput
                                                    label="Managerial Skills"
                                                    placeholder="Managerial Skills"
                                                    require={true}
                                                    value={item?.managerial_skills}
                                                    readOnly={true}
                                                />
                                            </div>
                                            <div className='col-md-3'>
                                                <CustomInput
                                                    label="Communication Skills"
                                                    placeholder="Communication Skills"
                                                    require={true}
                                                    value={item?.communication_skills}
                                                    readOnly={true}
                                                />
                                            </div>
                                            <div className='col-md-3'>
                                                <CustomInput
                                                    label="Presentation/Confidence"
                                                    placeholder="Presentation/Confidence"
                                                    require={true}
                                                    value={item?.presentation}
                                                    readOnly={true}
                                                />
                                            </div>
                                            <div className='col-md-4'>
                                                <CustomInput
                                                    label="Leadership Skills"
                                                    placeholder="Leadership Skills"
                                                    require={true}
                                                    value={item?.leadership_skills}
                                                    readOnly={true}
                                                />
                                            </div>
                                            <div className='col-md-8'>
                                                <CustomInput
                                                    label="Remarks(If any)"
                                                    require={true}
                                                    placeholder="Remarks(If any)"
                                                    value={item?.remarks}
                                                    readOnly={true}
                                                />
                                            </div>
                                            <div className='col-md-12'></div>
                                        </div>
                                    }
                                />
                            );
                        })}
                    </div>



                    {lastInterview?.is_interview_complete === true &&
                        <>
                            <div className='col-md-12'>
                                <CustomCard
                                    cardtitle={"For"}
                                    cardboldtitle={"HR Use"}
                                    cardbody={
                                        <div className='row'>
                                            {newState?.candidateOfficialInfo.length > 0 ?
                                                <>
                                                    {newState?.candidateOfficialInfo?.map((item, index) => {
                                                        return (<>
                                                            <div className='col-md-3'>
                                                                <CustomInput
                                                                    label="Job Location"
                                                                    require={true}
                                                                    readOnly={true}
                                                                    placeholder='Job Location'
                                                                    value={item?.job_location}
                                                                />
                                                            </div>
                                                            <div className='col-md-3'>
                                                                <CustomInput
                                                                    label="Position Offered"
                                                                    placeholder="Position Offered"
                                                                    require={true}
                                                                    readOnly={true}
                                                                    value={item?.position_offered}
                                                                />
                                                            </div>
                                                            <div className='col-md-3'>
                                                                <CustomInput
                                                                    label="Compensation Offered"
                                                                    placeholder="Compensation Offered"
                                                                    require={true}
                                                                    readOnly={true}
                                                                    value={item?.compensation_offered}
                                                                />
                                                            </div>
                                                            <div className='col-md-3'>
                                                                <CustomInput
                                                                    label="Tentative Date of Joining"
                                                                    placeholder="Tentative Date of Joining"
                                                                    require={true}
                                                                    readOnly={true}
                                                                    type="date"
                                                                    value={item?.tentative_doj?.split("T")[0]}
                                                                />
                                                            </div>
                                                            <div className='col-md-4'>
                                                                <CustomInput
                                                                    label="Leadership Skills"
                                                                    placeholder="Leadership Skills"
                                                                    require={true}
                                                                    readOnly={true}
                                                                    value={item?.leadership_skills}
                                                                />
                                                            </div>
                                                            <div className='col-md-8'>
                                                                <CustomInput
                                                                    label="Remarks(If any)"
                                                                    require={true}
                                                                    readOnly={true}
                                                                    placeholder="Remarks(If any)"
                                                                    value={item?.remarks}
                                                                />
                                                            </div>
                                                        </>)
                                                    })}

                                                </>
                                                : <>
                                                    <div className='col-md-3'>
                                                        <CustomInput
                                                            label="Job Location"
                                                            require={true}
                                                            placeholder='Job Location'
                                                            value={jobLocation}
                                                            onChange={(e) => setJobLocation(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <Customselect
                                                            label="Position Offered"
                                                            require={true}
                                                            value={positionOffered}
                                                            onChange={(e) => setPositionOffered(e.target.value)}
                                                            optionview={positionList?.map((option) => {
                                                                return (
                                                                    <option value={option?.position_id}>{option?.position_name}</option>
                                                                );
                                                            })}
                                                        />
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <CustomInput
                                                            label="Compensation Offered"
                                                            placeholder="Compensation Offered"
                                                            require={true}
                                                            value={compensationOffered}
                                                            onChange={(e) => setCompensationOffered(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <CustomInput
                                                            label="Tentative Date of Joining"
                                                            placeholder="Tentative Date of Joining"
                                                            require={true}
                                                            type="date"
                                                            value={tentativeDateOfJoining}
                                                            onChange={(e) => setTentativeDateOfJoining(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <CustomInput
                                                            label="Leadership Skills"
                                                            placeholder="Leadership Skills"
                                                            require={true}
                                                            value={leadershipSkills}
                                                            onChange={(e) => setLeadershipSkills(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className='col-md-8'>
                                                        <CustomInput
                                                            label="Remarks(If any)"
                                                            require={true}
                                                            placeholder="Remarks(If any)"
                                                            value={remarks}
                                                            onChange={(e) => setRemarks(e.target.value)}
                                                        />
                                                    </div>
                                                </>
                                            }

                                        </div>
                                    }
                                />
                            </div>




                        </>}

                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={"Audit"}
                            cardboldtitle={" trail"}
                            toprightview={
                                <>
                                    <CustomButton style={"me-3"} children={'Show All Pipeline'} onClick={toggleVisibility} />
                                    <button className="export-btn">
                                        <ExcelFile element={<div> <img width={20} className="me-2" src={require("../../../assets/images/excel.png")} />Export</div>}>
                                            <ExcelSheet data={auditTrail?.map((item, i) => ({
                                                ...item, index: i + 1, role: item?.role,
                                                created_at: item?.created_at && `${item.created_at?.split("T")[0]} ${item.created_at?.split("T")[1]?.split(".")[0].substring(0, 5)}`,
                                            }))} name="Sheet">
                                                <ExcelColumn label="Status Date & Time" value="created_at" />
                                                <ExcelColumn label="Name" value="userName" />
                                                <ExcelColumn label="Role" value="role" />
                                                <ExcelColumn label="Action Required" value="status" />
                                            </ExcelSheet>
                                        </ExcelFile>
                                    </button>
                                </>
                            }
                            cardbody={
                                <React.Fragment>
                                    <div class="table-responsive"> 
                                        <table class="table">
                                            <thead class="table-customlight">
                                                <tr>
                                                    <th className="manpower-table">Status Date & Time</th>
                                                    <th className="manpower-table">Candidate Name</th>
                                                    <th className="manpower-table">Interviewer Name</th>
                                                    <th className="manpower-table">Application Status</th>
                                                    <th className="manpower-table">Round</th>
                                                    <th className="manpower-table">Remark</th>
                                                </tr>
                                            </thead>
                                            {dataToDisplay?.length > 0 && (
                                                <tbody>
                                                    {dataToDisplay?.map((item, i) => {
                                                        return (
                                                            <tr key={item?.requestNumber}>
                                                                <td scope="row"><FormatDate dateString={item?.created_at} /> </td>
                                                                <td>{item?.candidate_name}</td>
                                                                <td>{item?.interviewer_name}</td>
                                                                {/* <td>{item?.is_application_status}</td> */}
                                                                <td>
                                                                    <div className="circle-box">
                                                                        <span className={getClassName(item?.is_application_status)}></span> {item?.is_application_status}
                                                                    </div>
                                                                </td>
                                                                <td>{item?.round}</td>
                                                                <td>{item?.remark}</td>
                                                            </tr>)
                                                    })}
                                                </tbody>)}
                                        </table>
                                    </div>
                                </React.Fragment>
                            }
                        />
                    </div>

                    {lastInterview?.is_interview_complete === true && newState?.candidateOfficialInfo.length === 0 && <div className='col-md-12 text-end'>
                        <CustomButton children={"Submit"} style={"btn--approved--solid"} onClick={handleScheduleInterview} />
                    </div>}

                </div>
            </div>
        </div >
    )
}

export default ScheduleInterview