// import React from "react";
// import Modal from "react-bootstrap/Modal";
// import "./style.scss";
// import crossImage from "../../assets/images/close (4).png";
// import CustomButton from "../Button/Button";
// import CustomInput from "../CustomInput";
// import Textarea from "../CustomeTextarea";

// const AddTRNumberModal = (props) => {


//     return (
//         <Modal {...props} centered className="sucess-content sucess_content_modal">
//             <Modal.Body className="sucess-modal py-5">
//                 <div className="modal-container">
//                     <img
//                         src={crossImage}
//                         alt=""
//                         className="cross-img"
//                         onClick={() => props.hide()}
//                     />
//                     <div className="modal_submit ">
//                         <h4 className="success_title1" style={{ textAlign: "center" }}>
//                             Add TR Number
//                         </h4>
//                         <div className="addName-model">
//                             <div className="container-fluid">
//                                 <div className="row">
//                                     <div className="col-md-6 text-start">
//                                         <CustomInput
//                                             label={'Number'}
//                                             placeholder={'Enter Number'}
//                                             value={props?.tr_number}
//                                             onChange={(e) => props?.handleTR(e.target.value)}
//                                             require
//                                         />
//                                     </div>

//                                     <div className="col-md-6 text-start">
//                                         <CustomInput
//                                             label={'Description'}
//                                             require
//                                             // style={'textarea_bg'}
//                                             value={props?.description}
//                                             onChange={(e) => props?.handleDesc(e.target.value)}
//                                             placeholder={'Please write a Description....'}
//                                             defaultValue />
//                                     </div>


//                                 </div>
//                             </div>

//                         </div>
//                         <CustomButton
//                             children={"Add+"}
//                             style={'btn--outline me-4'}
//                             onClick={""}
//                         />
//                         <CustomButton
//                             children={"Submit"}
//                             style={'btn--approved--solid'}
//                             onClick={props?.handleSendTrDetails}
//                         />

//                     </div>
//                 </div>

//             </Modal.Body>
//         </Modal>
//     );
// };

// export default AddTRNumberModal;
import React from "react";
import Modal from "react-bootstrap/Modal";
import "./style.scss";
import crossImage from "../../assets/images/close (4).png";
import CustomButton from "../Button/Button";
import CustomInput from "../CustomInput";

const AddTRNumberModal = (props) => {
    const { trDetails, handleTR, handleDesc, handleAddInputGroup, handleRemoveInputGroup } = props;

    return (
        <Modal {...props} centered className="sucess-content sucess_content_modal">
            <Modal.Body className="sucess-modal py-5">
                <div className="modal-container">
                    <img
                        src={crossImage}
                        alt="Close"
                        className="cross-img"
                        onClick={props.hide}
                    />
                    <div className="modal_submit">
                        <h4 className="success_title1" style={{ textAlign: "center" }}>
                            Add TR Number
                        </h4>
                        <div className="addName-model">
                            <div className="container-fluid">
                                <div className="row">
                                    {trDetails?.map((detail, index) => (
                                                 <div key={index} className="row tr-box">
                                            <div className="col-md-6 text-start">
                                                <CustomInput
                                                    label="Number"
                                                    placeholder="Enter Number"
                                                    value={detail.tr_number}
                                                    onChange={(e) => handleTR(index, e.target.value)}
                                                    require
                                                />
                                            </div>
                                            <div className="col-md-6 text-start">
                                                <CustomInput
                                                    label="Description"
                                                    require
                                                    value={detail.description}
                                                    onChange={(e) => handleDesc(index, e.target.value)}
                                                    placeholder="Please write a Description...."
                                                />
                                            </div>
                                            {index > 0 && (
                                                <div className="col-md-12 text-end">
                                                    <CustomButton
                                                        type="button"
                                                        className="btn btn-danger"
                                                        onClick={() => handleRemoveInputGroup(index)}
                                                        children={"Delete"}

                                                    />
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="text-center mt-3">
                            <CustomButton
                                children={"Add+"}
                                style={"btn--outline me-4"}
                                onClick={handleAddInputGroup}
                            />
                            <CustomButton
                                children={"Submit"}
                                style={"btn--approved--solid"}
                                onClick={props.handleSendTrDetails}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AddTRNumberModal;
