import React, { useEffect, useState, useRef } from 'react'
import HrRequisitionPortal from '../../../component/HrNewModuleComponent/HrRequisitionPortal';
import Header from '../../../component/Header'
import ReactPaginate from "react-paginate";
import './style.scss'
import { useLocation, useNavigate } from "react-router-dom"
import { useStores } from '../../../stores';
import notify from '../../../shared/notify';
import Loader from '../../../component/helper/Loader/loader';


const limit = [
  {
    option: 10,
    value: 10,
  },
  {
    option: 100,
    value: 100,
  },
  {
    option: 500,
    value: 500,
  },
  {
    option: 1000,
    value: 1000,
  },
];

const HrPortalDashboard = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [allCandidateList, setAllCandidateList] = useState([])
  const [candidateList, setCandidateList] = useState([])
  const { crmStore } = useStores()
  const [page, setPage] = useState(parseInt(sessionStorage.getItem('currentHrPage')) || 1);
  const [pageLimit, setPageLimit] = useState(parseInt(sessionStorage.getItem('pageHrLimit')) || 10);
  const navigate = useNavigate()
  const [filterName, setFilterName] = useState("")
  const [filterEmail, setFilterEmail] = useState("")
  const [filterContact, setFilterContact] = useState("")
  const [filterInterviewer, setFilterInterviewer] = useState("")
  const [interviewList, setInterviewList] = useState([])
  const targetRowRef = useRef(null);

  useEffect(() => {
    fetchInterviewer()
  }, [])


  const fetchInterviewer = async () => {
    await crmStore.interviewer((status, res) => {
      if (res?.status === "success") {
        setInterviewList(res?.data)
      } else {
        console.log(res?.data?.meta?.message, "setDeparmentList")
      }
    })
  }


  const handleView = (item) => {
    if (item?.is_form_status != "Completed") {
      return notify("The candidate has not yet filled the application form", "danger")
    }
    navigate('/schedule-applicant-interview', { state: { item } })
  }

  const handleAddNewRequisition = () => {
    navigate('/generate-applicant-link')
  }

  // useEffect(() => {
  //   fetchAllCandidate()
  // }, [])

  // const fetchAllCandidate = async () => {
  //   let payload = {
  //     limit: pageLimit,
  //     filters: {
  //     },
  //     pageNumber: page
  //   }
  //   callCandidateApi(payload)
  // }

  const callCandidateApi = async (payload) => {
    setIsLoading(true)
    await crmStore.getAllCandidateForm(payload, (res) => {
      if (res?.data?.meta?.status === "success") {
        setIsLoading(false)
        setAllCandidateList(res?.data?.data?.filteredData?.results)
        setCandidateList(res?.data?.data)
        if (targetRowRef.current) {
          setTimeout(() => {
            targetRowRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }, 1000); // Adjust the timeout as necessary
        }

      } else {
        notify(res?.data?.meta?.message, "danger")
        setIsLoading(false)

      }
    }, (error) => {
      notify(error?.response?.data?.meta?.message, "danger")
      setIsLoading(false)

    })
  }


  const handlePageSubmit = async (index) => {
    const newPage = index.selected + 1;
    setPage(newPage);
    let storedFilterInterviewer = sessionStorage.getItem('filterInterviewer');
    sessionStorage.setItem('currentHrPage', newPage);
    let payload = {
      limit: pageLimit,
      filters: {
        candidateInterview: {
          interviewer_id: storedFilterInterviewer || "",
        },
      },
      pageNumber: index.selected + 1,
    };
    callCandidateApi(payload);
  };




  const limitChangeHandler = async (index) => {
    setPageLimit(index);
    sessionStorage.setItem('pageHrLimit', index);
    let payload = {
      limit: index,
      filters: {},
      pageNumber: page,
    };
    callCandidateApi(payload);
  };

  useEffect(() => {
    const storedFilterName = sessionStorage.getItem('filterName');
    const storedFilterEmail = sessionStorage.getItem('filterEmail');
    const storedFilterContact = sessionStorage.getItem('filterContact');
    const storedFilterInterviewer = sessionStorage.getItem('filterInterviewer');

    if (storedFilterName) setFilterName(storedFilterName);
    if (storedFilterEmail) setFilterEmail(storedFilterEmail);
    if (storedFilterContact) setFilterContact(storedFilterContact);
    if (storedFilterInterviewer) setFilterInterviewer(storedFilterInterviewer);


    let payload = {
      limit: pageLimit,
      filters: {
        full_name: storedFilterName || "",
        email_id: storedFilterEmail || "",
        contact: storedFilterContact || "",
        candidateInterview: {
          interviewer_id: storedFilterInterviewer || "",
        },
      },
      pageNumber: page,
    };


    callCandidateApi(payload);
    const interval = setInterval(() => {
      callCandidateApi(payload);
    }, 500000);
    return () => clearInterval(interval);
  }, [pageLimit, page]);


  const handleFilter = async () => {
    setCandidateList([]);
    setAllCandidateList([]);
    sessionStorage.setItem('filterName', filterName);
    sessionStorage.setItem('filterEmail', filterEmail);
    sessionStorage.setItem('filterContact', filterContact);
    sessionStorage.setItem('filterInterviewer', filterInterviewer);

    let payload = {
      limit: pageLimit,
      filters: {
        full_name: filterName,
        email_id: filterEmail,
        contact: filterContact,
        candidateInterview: {
          interviewer_id: filterInterviewer,
        },
      },
      pageNumber: 1,
    };

    callCandidateApi(payload);
  };


  const handleReset = async () => {
    setFilterContact("")
    setFilterEmail("")
    setFilterName("")
    setFilterInterviewer("")
    setPage(1);
    setPageLimit(10);
    setCandidateList([]);
    setAllCandidateList([]);
    // sessionStorage.removeItem("currentHrPage")
    // sessionStorage.removeItem("pageHrLimit")
    sessionStorage.removeItem('filterName');
    sessionStorage.removeItem('filterEmail');
    sessionStorage.removeItem('filterContact');
    sessionStorage.removeItem('filterInterviewer');
    sessionStorage.removeItem("currentHrPage");
    sessionStorage.removeItem("pageHrLimit");
    // await fetchAllCandidate();
    let payload = {
      limit: 10,
      filters: {
      },
      pageNumber: 1
    }
    callCandidateApi(payload);
  };

  return (
    <div className="main-container">
      {isLoading && <Loader />}
      <div className="right-view">
        <Header title={"Scheduled Interviews,"} />
        <div className='row'>
          <div className='col-md-12'>
            <HrRequisitionPortal
              handleView={handleView}
              handleAddNewRequisition={handleAddNewRequisition}
              allCandidateList={allCandidateList}
              candidateList={candidateList}
              handlePageSubmit={handlePageSubmit}
              page={page}
              limitChangeHandler={limitChangeHandler}
              limit={limit}
              pageLimit={pageLimit}
              handleFilter={handleFilter}
              handleReset={handleReset}
              filterInterviewer={filterInterviewer}
              filterContact={filterContact}
              filterEmail={filterEmail}
              filterName={filterName}
              handleInterviewer={(e) => setFilterInterviewer(e)}
              handleEmail={(e) => setFilterEmail(e)}
              handleName={(e) => setFilterName(e)}
              handleContact={(e) => setFilterContact(e)}
              interviewList={interviewList}
              targetRowRef={targetRowRef}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HrPortalDashboard