import React, { useEffect, useState } from "react";
import Header from "../../../../component/Header";
import CustomCard from "../../../../component/CustomeCard";
import "./style.scss";
import CustomButton from "../../../../component/Button/Button";
import CustomInput from "../../../../component/CustomInput";
import Customselect from "../../../../component/CustomSelect";
import TextEditor from "../../../../component/CustomeTextEditior";
import Textarea from "../../../../component/CustomeTextarea";
import SubmitTicketModal from "../../../../component/CRMModuleComponent/successModal";
import { useStores } from "../../../../stores";
import notify from "../../../../shared/notify";
import Loader from "../../../../component/helper/Loader/loader";
import { useNavigate } from "react-router-dom";



const AddTickets = () => {
    const role = sessionStorage.getItem("role")

    const { mainStore, crmStore } = useStores()
    const navigate = useNavigate();
    const candidateString = sessionStorage.getItem("candidate");
    const candidateData = JSON.parse(candidateString);
    const [submitModal, setSubmitModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const name = sessionStorage.getItem("name")
    const [summary, setSummary] = useState('');
    const [description, setDescription] = useState("");
    const [reporter, setReporter] = useState(name)

    const [issueTypeList, setIssueTypeList] = useState([]);
    const [selectedIssueType, setSelectedIssueType] = useState('');
    const [entityList, setEntityList] = useState([]);
    const [selectedEntity, setSelectedEntity] = useState('');

    const [plantList, setPlantList] = useState([]);
    const [selectedPlant, setSelectedPlant] = useState('');

    const [categoryList, setCategoryList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');

    const [subCategoryList, setSubCategoryList] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState('');

    const [statusList, setStatusList] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState('');

    const [priorityList, setPriorityList] = useState([]);
    const [selectedPriority, setSelectedPriority] = useState('');



    useEffect(() => {
        fetchAllCategories();
        getAllEntity();
        getPriorities();
        getIssuesTypes();
    }, [])

    const getPriorities = () => {
        crmStore.getPriorityNoFilters((status, res) => {
            if (res?.meta?.status === "success") {
                setPriorityList(res?.data?.getAllComplaintPriorityLevels)
            } else {
                console.log(res?.data?.meta?.message, "setDeparmentList")
            }
        })
    }


    const getIssuesTypes = async () => {
        await crmStore?.getIssueTypeNoFilters((status, res) => {
            if (res?.meta?.status === "success") {
                setIssueTypeList(res?.data?.getAllComplaintIssueType)
            }
        }, (err) => {
            notify(err?.response?.meta?.message, "danger")
        })
    };


    const fetchAllCategories = () => {
        setIsLoading(true)
        crmStore.getAllCategories(

            (status, res) => {
                console.log(res)
                if (res?.meta?.status === "success") {
                    setCategoryList(res.data.categories);
                    setIsLoading(false)
                } else {
                    setIsLoading(false)
                }
            },
            (error) => {
                notify(error?.response?.data?.meta?.message, "danger")
                setIsLoading(false)
            })
    }

    const fetchSubCategoryById = (category_id) => {
        let payload = {
            multupleCategoryIds: [category_id]
        }
        crmStore.getAllSubcategories(
            payload,
            (res) => {
                if (res?.data?.meta?.status === "success") {
                    setSubCategoryList(res.data.data.filteredData);
                }
            },
            (error) => {
                notify(error?.response?.data?.meta?.message, "danger")
                setIsLoading(false)
            })
    }

    const getAllEntity = async () => {
        await mainStore.getEntity((status, res) => {
            if (res?.meta?.status === "success") {
                setEntityList(res?.data?.entitites)
            } else {
                console.log(res?.data?.meta?.message, "businessList")
            }
        })
    }

    const getPlantByCompanyId = async (id) => {
        let payload = {
            company_id: id
        }
        await crmStore.getAllCompanyPlant(payload, (res) => {
            if (res?.data?.meta?.status === "success") {
                setPlantList(res?.data?.data)
            } else {
                console.log(res?.data?.meta?.message, "businessList")
            }
        })
    }
    const handleEditorChange = (content) => {
        console.log("Processed editor content", content);
        setDescription(content);
    };



    const handleSubmit = async () => {
        let payload = {
            "issue_summary": summary,
            "entity_id": selectedEntity,
            "priority_id": selectedPriority,
            description: description,
            "category_id": selectedCategory,
            "subcategory_id": selectedSubCategory,
            "issue_type_id": selectedIssueType,
            "plant_id": selectedPlant,
        }
        console.log(payload)
        setIsLoading(true)
        await crmStore?.createComplaint(payload, (res) => {
            if (res?.data?.meta?.status === "success") {
                setSubmitModal(true)
                console.log(res)
                setIsLoading(false)
            } else {
                setSubmitModal(false)
                console.log(res)
                setIsLoading(false)
            }

        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
            setIsLoading(false)
            setSubmitModal(false)
        })
    }


    return (
        <>
            {isLoading && <Loader />}
            <div className="main-container raiseTicket_container_crm">
                <div className="right-view">
                    <Header title={'Raise New Ticket'} />
                    <div className="container-fluid px-0">
                        <div className="row">

                            <div className="col-md-12">
                                <CustomCard
                                    cardtitle={"Ticket"}
                                    cardboldtitle={"Details"}
                                    cardbody={
                                        <>
                                            <img src={require("../../../../assets/images/left-arrow.png")} className="back-btn-dashboard" onClick={() => navigate(-1)} />
                                            <div className="ticket_detaile_box">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <Textarea
                                                            label={'Summary'}
                                                            require
                                                            placeholder={'Please write a comment.....'}
                                                            value={summary}
                                                            onChange={(e) => { setSummary(e.target.value) }}
                                                        />
                                                    </div>
                                                    <div className="col-md-12 textarea">
                                                        <TextEditor
                                                            label={'Description'}
                                                            require
                                                            placeholder={'Please write a comment.....'}
                                                            value={description}
                                                            onChange={handleEditorChange}
                                                            className="textarea"
                                                        />
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <CustomInput
                                                            label={'Reporter'}
                                                            require
                                                            placeholder={'Reporter'}
                                                            // value={candidateData.name}
                                                            value={reporter}
                                                            onChange={(e) => setReporter(e.target.value)}
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <Customselect
                                                            label={'Ticket Type'}
                                                            require
                                                            value={selectedIssueType}
                                                            onChange={(e) => { setSelectedIssueType(e.target.value) }}
                                                            optionview={

                                                                issueTypeList?.map((item) => {
                                                                    return (
                                                                        <option value={item?.id}>{item?.name}</option>
                                                                    )
                                                                })
                                                            }
                                                        />
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <Customselect
                                                            label={'Entity'}
                                                            require
                                                            placeholder={'Entity'}
                                                            value={selectedEntity}
                                                            onChange={(e) => {
                                                                setSelectedEntity(e.target.value)
                                                                getPlantByCompanyId(e.target.value)
                                                            }}
                                                            optionview={
                                                                entityList?.length > 0 &&
                                                                entityList?.map((item) => {
                                                                    return (
                                                                        <option value={item?.entity_code}>{item?.name}</option>
                                                                    )
                                                                })
                                                            }
                                                        />
                                                    </div>

                                                    <div className="col-md-3">
                                                        <Customselect
                                                            label={'Plant'}
                                                            require
                                                            placeholder={'Plant'}
                                                            value={selectedPlant}
                                                            onChange={(e) => {
                                                                setSelectedPlant(e.target.value)
                                                            }}
                                                            optionview={
                                                                plantList?.length > 0 &&
                                                                plantList?.map((item) => {
                                                                    return (
                                                                        <option value={item?.plant_id}>{item?.plant_name}</option>
                                                                    )
                                                                })
                                                            }
                                                        />
                                                    </div>


                                                    <div className="col-md-3">
                                                        <Customselect
                                                            label={'Select Category'}
                                                            require
                                                            value={selectedCategory}
                                                            onChange={(e) => {
                                                                setSelectedCategory(e.target.value)
                                                                fetchSubCategoryById(e.target.value)
                                                            }}
                                                            optionview={
                                                                categoryList?.length > 0 &&
                                                                categoryList?.map((item) => {
                                                                    return (
                                                                        <option value={item?.category_id}>{item?.category_name}</option>
                                                                    )
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <Customselect
                                                            label={'Select Sub-Category'}
                                                            require
                                                            value={selectedSubCategory}
                                                            onChange={(e) => {
                                                                setSelectedSubCategory(e.target.value)
                                                            }}
                                                            optionview={
                                                                subCategoryList?.length > 0 &&
                                                                subCategoryList?.map((item) => {
                                                                    return (
                                                                        <option value={item?.subcategory_id}>{item?.subcategory_name}</option>
                                                                    )
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <Customselect
                                                            label={'Select Priority'}
                                                            require
                                                            value={selectedPriority}
                                                            onChange={(e) => { setSelectedPriority(e.target.value) }}
                                                            optionview={
                                                                priorityList?.length > 0 &&
                                                                priorityList?.map((item) => {
                                                                    return (
                                                                        <option value={item?.priority_level}>{item?.priority_name}</option>
                                                                    )
                                                                })
                                                            }
                                                        />
                                                    </div>

                                                    <div className="col-md-12 text-end">
                                                        <CustomButton children={'Submit'} style={'btn--approved--solid'} onClick={handleSubmit} />
                                                    </div>
                                                </div>
                                            </div>
                                        </>}
                                />
                            </div>

                        </div>
                    </div>
                </div>
                <SubmitTicketModal
                    show={submitModal}
                    hide={() => {
                        setSubmitModal(false)
                        navigate("/Dashboard")
                    }}
                    title={"We have received your query. Team is working on it."}
                />
            </div >
        </>
    );
};

export default AddTickets;
