import React , {useState} from "react";
import './customInput.scss';

const CustomInput = (props) => {
  const { label, type = "text", name, value, onChange, placeholder, style ,readOnly , min ,  onKeyDown} = props;
  const [isTyping, setIsTyping] = useState(false); 
 const handleFocus = () => {
    setIsTyping(true);
  };

  const handleBlur = () => {
    setIsTyping(false); 
  };

  return (
    <div className="custominput-container">
      <div className={type=="date" ? "custom-input login-text" :"custom-input "}  >
        <label>{label}{props.require && <span className="text-danger">*</span>}</label>
        <input
          className={style}
          readOnly={readOnly}
          type={type}
          placeholder={placeholder}
          name={name}
          value={value}
          min={min}
          onKeyDown={onKeyDown} 
          style={{ backgroundColor: readOnly ? '#e3e3e3' : 'white' ,  textTransform: isTyping ? 'none' : 'capitalize',}}
          onChange={onChange} 
          onBlur={handleBlur} 
          onFocus={handleFocus}
          />
          
      </div>
    </div>
  )
}

export default CustomInput; 