import React, { useEffect, useState,useRef } from 'react'
import HrRequisitionPortal from '../../../component/HrNewModuleComponent/HrRequisitionPortal';
import Header from '../../../component/Header'
import ReactPaginate from "react-paginate";
import './style.scss'
import { useLocation, useNavigate } from "react-router-dom"
import { useStores } from '../../../stores';
import notify from '../../../shared/notify';
import Loader from '../../../component/helper/Loader/loader';


const limit = [
  {
    option: 10,
    value: 10,
  },
  {
    option: 100,
    value: 100,
  },
  {
    option: 500,
    value: 500,
  },
  {
    option: 1000,
    value: 1000,
  },
];

const HrPortalApprover = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [allCandidateList, setAllCandidateList] = useState([])
  const [candidateList, setCandidateList] = useState([])
  const { crmStore } = useStores()
  const [page, setPage] = useState(parseInt(sessionStorage.getItem('currentHrPage')) || 1);
  const [pageLimit, setPageLimit] = useState(parseInt(sessionStorage.getItem('pageHrLimit')) || 10);
  const navigate = useNavigate()
  const [filterName, setFilterName] = useState("")
  const [filterEmail, setFilterEmail] = useState("")
  const [filterContact, setFilterContact] = useState("")
  const [filterInterviewer, setFilterInterviewer] = useState("")
  const name = sessionStorage.getItem("name")
  const candidateString = sessionStorage.getItem("candidate");
  const candidate = candidateString ? JSON.parse(candidateString) : null;
  const targetRowRef = useRef(null);

  // useEffect(()=>{
  //   fetchAllCandidate()
  // },[])


  const handleView = (item) => {
    if(item?.is_form_status != "Completed"){
      return notify("The candidate has not yet filled the application form" , "danger")
    }
    navigate('/interviwer-from', { state: { item } })
  }

  const handleAddNewRequisition = () => {
    navigate('/generate-applicant-link')
  }


  
  useEffect(() => {
    fetchAllCandidate();
    const interval = setInterval(() => {
      fetchAllCandidate();
    }, 500000);
    return () => clearInterval(interval);
  }, [pageLimit, candidate?.id, page]);
  

  const fetchAllCandidate = async () => {
    let payload = { 
        limit: pageLimit,
        filters: {
            candidateInterview: {
                interviewer_id: candidate?.id,
                is_interview_complete:false
            }
        },
        pageNumber: page
    }
    callCandidateApi(payload)
  }

  const callCandidateApi = async (payload) => {
    setIsLoading(true)
    await crmStore.getAllCandidateForm(payload, (res) => {
      if (res?.data?.meta?.status === "success") {
        setIsLoading(false)
        setAllCandidateList(res?.data?.data?.filteredData?.results)
        setCandidateList(res?.data?.data)
        console.log((res?.data?.data?.filteredData?.results));
        if (targetRowRef.current) {
          setTimeout(() => {
              targetRowRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }, 1000); // Adjust the timeout as necessary
      }

      } else {
        notify(res?.data?.meta?.message, "danger")
        setIsLoading(false)

      }
    }, (error) => {
      notify(error?.response?.data?.meta?.message, "danger")
      setIsLoading(false)

    })
  }


  const handlePageSubmit = async (index) => {
    const newPage = index.selected + 1;
    setPage(newPage);
    console.log("**************", newPage)
    sessionStorage.setItem('currentHrPage', newPage);
    let payload = {
      limit: pageLimit,
      filters: {
        candidateInterview: {
          interviewer_id: candidate?.id,
          is_interview_complete:false
      }
      },
      pageNumber: index.selected + 1,
    };
    callCandidateApi(payload);
  };




  const limitChangeHandler = async (index) => {
    setPageLimit(index);
    sessionStorage.setItem('pageHrLimit', index);
    let payload = {
      limit: index,
      filters: {
        candidateInterview: {
          interviewer_id: candidate?.id,
          is_interview_complete:false
      }
      },
      pageNumber: page,
    };
    callCandidateApi(payload);
  };


  const handleFilter = async () => {
    let payload = {
      limit: pageLimit,
      filters: {
        full_name: filterName,
        email_id: filterEmail,
        contact: filterContact,
        interviewer: filterInterviewer
      },
      pageNumber: page,
    };

    callCandidateApi(payload);
  };

  const handleReset = async () => {
    setFilterContact("")
    setFilterEmail("")
    setFilterName("")
    setFilterInterviewer("")
    setPage(1);
    setPageLimit(10);
    sessionStorage.removeItem("currentHrPage")
    sessionStorage.removeItem("pageHrLimit")
    let payload = {
      limit: 10,
      filters: {
      },
      pageNumber: 1
    }
    callCandidateApi(payload);
  };

  return (
    <div className="main-container">
      {isLoading && <Loader />}
      <div className="right-view">
        <Header title={"Assigned Interviews,"} />
        <div className='row'>
          <div className='col-md-12'>
            <HrRequisitionPortal
              handleView={handleView}
              handleAddNewRequisition={handleAddNewRequisition}
              allCandidateList={allCandidateList}
              candidateList={candidateList}
              handlePageSubmit={handlePageSubmit}
              page={page}
              limitChangeHandler={limitChangeHandler}
              limit={limit}
              pageLimit={pageLimit}
              handleFilter={handleFilter}
              handleReset={handleReset}
              filterInterviewer={filterInterviewer}
              filterContact={filterContact}
              filterEmail={filterEmail}
              filterName={filterName}
              handleInterviewer={(e) => setFilterInterviewer(e)}
              handleEmail={(e) => setFilterEmail(e)}
              handleName={(e) => setFilterName(e)}
              handleContact={(e) => setFilterContact(e)}
              targetRowRef={targetRowRef}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HrPortalApprover