import React, { useState, useEffect } from "react";
import Header from "../../../../component/Header";
// import "./style.scss";
import Loader from "../../../../component/helper/Loader/loader";
import { useNavigate } from "react-router-dom";
import { useStores } from "../../../../stores";
import notify from "../../../../shared/notify";
import HistoryDashboard from "../../../../component/CRMModuleComponent/HistoryDashboard";

const limit = [
  { option: 10, value: 10, },
  { option: 100, value: 100, },
  { option: 500, value: 500, },
  { option: 1000, value: 1000, },
];

const HistoryTicket = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [complaintData, setComplaintData] = useState([]);
  const [allComplainData, setAllComplainData] = useState([]);
  const navigate = useNavigate();
  const { crmStore } = useStores();
  const [page, setPage] = useState(parseInt(sessionStorage.getItem('currentPageHistory')) || 1);
  const [pageLimit, setPageLimit] = useState(parseInt(sessionStorage.getItem('pageLimitHistory')) || 10);
  const [categoryList, setCategoryList] = useState([]);
  const [selectCategory, setSelectCategory] = useState(sessionStorage.getItem('selectedCategoryHistory') || "");
  const [priorityList, setPriorityList] = useState([]);
  const [selectedPriority, setSelectedPriority] = useState(sessionStorage.getItem('selectedPriorityHistory') || "");
  const [toDate, setToDate] = useState(sessionStorage.getItem('toDateHistory') || "")
  const [fromDate, setFromDate] = useState(sessionStorage.getItem('fromDateHistory') || "")
  const [complaintStatusList, setComplaintStatusList] = useState([])
  const [selectedComplaintStatus, setSelectedComplaintStatus] = useState(sessionStorage.getItem('selectedComplaintStatusHistory') || "")
  let fetchRole = sessionStorage.getItem("role");
  const role = fetchRole?.split(",");
  let sessions = sessionStorage.getItem("candidate");
  let session = JSON.parse(sessions);
  let crmApprover = ["L2", "L3"]

  useEffect(() => {
    fetchAllCategories();
    getPriorities();
    CallFetchedStatus()
    const storedCategory = sessionStorage.getItem('selectedCategoryHistory');
    const storedPriority = sessionStorage.getItem('selectedPriorityHistory');
    const storedtoDate = sessionStorage.getItem('toDateHistory');
    const storedfromDate = sessionStorage.getItem('fromDateHistory');
    const storedselectedComplaintStatus = sessionStorage.getItem('selectedComplaintStatusHistory');

    if (storedCategory) setSelectCategory(storedCategory);
    if (storedPriority) setSelectedPriority(storedPriority);
    if (storedtoDate) setToDate(storedtoDate);
    if (storedfromDate) setFromDate(storedfromDate);
    if (storedselectedComplaintStatus) setSelectedComplaintStatus(storedselectedComplaintStatus);


    FetchedComplain();
  }, [pageLimit]);

  const FetchedComplain = async () => {
    let payload = {
      limit: pageLimit,
      filters: {},
      pageNumber: page,
    };

    if (selectCategory) {
      payload.filters.category_name = selectCategory;
    }
    if (selectedPriority) {
      payload.filters.priority_name = selectedPriority;
    }
    if (toDate) {
      payload.filters.to_date = toDate;
    }
    if (fromDate) {
      payload.filters.from_date = fromDate;
    }
    if (selectedComplaintStatus) {
      payload.filters.complaint_status_name = selectedComplaintStatus;
    }

    CallFetchedComplain(payload);
  };

  const CallFetchedStatus = async () => {
    await crmStore.getStatus((status, res) => {
      if (res?.meta?.status === "success") {
        setComplaintStatusList(res.data.complaintStatuses);
      }
    }, (error) => {
      notify(error?.response?.data?.meta?.message, "danger");
    });
  }

  const CallFetchedComplain = async (payload) => {
    setIsLoading(true);
    await crmStore.historyTicket(session?.id ,payload,(res) => {
        if (res?.data?.meta?.status === "success") {
          setComplaintData(res?.data?.data?.filteredData?.results);
          setAllComplainData(res?.data?.data);
          setIsLoading(false);
        }
      },
      (err) => {
        notify(err?.response?.data?.meta?.message, "danger");
        setIsLoading(false);
      }
    );
  };

  const handlePageSubmitt = async (index) => {
    debugger;
    const newPage = index.selected + 1;
    setPage(newPage);
    sessionStorage.setItem('currentPageHistory', newPage);

    let payload = {
      limit: pageLimit,
      filters: {},
      pageNumber: newPage,
    };

    if (selectCategory) {
      payload.filters.category_name = selectCategory;
    }
    if (selectedPriority) {
      payload.filters.priority_name = selectedPriority;
    }
    if (toDate) {
      payload.filters.to_date = toDate;
    }
    if (fromDate) {
      payload.filters.from_date = fromDate;
    }
    if (selectedComplaintStatus) {
      payload.filters.complaint_status_name = selectedComplaintStatus;
    }

    CallFetchedComplain(payload);
  };

  const limitChangeHandlerr = async (index) => {
    setPageLimit(index);
    sessionStorage.setItem('pageLimitHistory', index);

    let payload = {
      limit: index,
      filters: {},
      pageNumber: page,
    };

    if (selectCategory) {
      payload.filters.category_name = selectCategory;
    }
    if (selectedPriority) {
      payload.filters.priority_name = selectedPriority;
    }
    if (toDate) {
      payload.filters.to_date = toDate;
    }
    if (fromDate) {
      payload.filters.from_date = fromDate;
    }
    if (selectedComplaintStatus) {
      payload.filters.complaint_status_name = selectedComplaintStatus;
    }

    CallFetchedComplain(payload);
  };

  const handleFilterr = async () => {
    sessionStorage.setItem('selectedCategoryHistory', selectCategory);
    sessionStorage.setItem('selectedPriorityHistory', selectedPriority);
    sessionStorage.setItem('toDateHistory', toDate);
    sessionStorage.setItem('fromDateHistory', fromDate);
    sessionStorage.setItem('selectedComplaintStatusHistory', selectedComplaintStatus);

    FetchedComplain();
  };

  const handleResett = async () => {
    setSelectCategory("");
    setSelectedPriority("");
    setToDate("")
    setFromDate("")
    setSelectedComplaintStatus("")
    setPage(1);
    setPageLimit(10);

    sessionStorage.removeItem('selectedCategoryHistory');
    sessionStorage.removeItem('selectedPriorityHistory');
    sessionStorage.removeItem('toDateHistory');
    sessionStorage.removeItem('fromDateHistory');
    sessionStorage.removeItem('selectedComplaintStatusHistory');
    sessionStorage.removeItem('currentPageHistory');
    sessionStorage.removeItem('pageLimitHistory');

    let payload = {
      limit: 10,
      filters: {},
      pageNumber: 1,
    };
    CallFetchedComplain(payload);
  };

  const fetchAllCategories = () => {
    crmStore.getAllCategories(
      (status, res) => {
        if (res?.meta?.status === "success") {
          setCategoryList(res.data.categories);
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
      }
    );
  };

  const getPriorities = () => {
    crmStore.getPriorityNoFilters((status, res) => {
      if (res?.meta?.status === "success") {
        setPriorityList(res?.data?.getAllComplaintPriorityLevels);
      } else {
        console.log(res?.data?.meta?.message, "setDeparmentList");
      }
    });
  };

  return (
    <div className="main-container raiseTicket_container_crm">
      {isLoading && <Loader />}
      <div className="right-view">
        <Header title={"Assigned Ticket History,"} />
        <div className="container-fluid px-0">
          <HistoryDashboard
            allComplainData={allComplainData}
            complaintData={complaintData}
            handlePageSubmit={handlePageSubmitt}
            page={page}
            pageLimit={pageLimit}
            limit={limit}
            limitChangeHandler={limitChangeHandlerr}
            handleFilter={handleFilterr}
            handleReset={handleResett}
            handleView={(item) => navigate('/view-ticket', { state: { item } })}
            categoryList={categoryList}
            selectCategory={selectCategory}
            handleSelectCategory={(e) => setSelectCategory(e)}
            handlePriority={(e) => setSelectedPriority(e)}
            selectedPriority={selectedPriority}
            priorityList={priorityList}
            FetchedComplain={FetchedComplain}
            handleToDate={(e) => setToDate(e)}
            handleFromDate={(e) => setFromDate(e)}
            toDate={toDate}
            fromDate={fromDate}
            complaintStatusList={complaintStatusList}
            handleSelectStatus={(e) => setSelectedComplaintStatus(e)}
            selectedComplaintStatus={selectedComplaintStatus}
          />
        </div>
      </div>
    </div>
  );
};

export default HistoryTicket;
